import { format, parse, startOfDay, subDays } from 'date-fns';
import { pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import ooeConstants from '../constants';
import {
  actions,
  keys,
  selectIsPOSOrder,
  selectLastEndDate,
  selectSpecificDays,
  types,
} from '../reducers/dashboard';
import { types as orderTypes } from '../reducers/order';
import {
  selectAccessToken,
  selectLocationNumber,
  types as userTypes,
} from '../reducers/user';
import {
  cancelOrderAdminApi,
  getAdditionalPastOrdersFromApi,
  getRecentAndUpcomingOrdersFromApi,
  lookupCustomerDetailsFromCustomerID,
  lookupOrderDetailsFromApi,
  lookupPOSOrderDetails,
  resendPaymentEmail,
} from '../services/dashboardApi';
import epicHelper, { epicOptions } from '../util/myEpicHelper';

export const GetOrders = (action$, store) =>
  action$.pipe(
    ofType(
      types.GET_ORDERS,
      userTypes.UPDATE_USER_LOCATION,
      userTypes.GET_USER_LOCATIONS_SUCCESS,
      orderTypes.SUBMIT_ORDER_SUCCESS,
      types.LOAD_MORE_PAST_ORDERS,
    ),
    debounceTime(ooeConstants.DXE_DASHBOARD_DELAY_TIME),
    switchMap((action) => {
      const { key } = action;
      const state = store.value;
      const locationId = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      const numberOfPreviousDays =
        ooeConstants.NUM_ADDTL_DASHBOARD_PAST_ORDERS_TO_LOAD;

      let successAction = actions.ordersSuccess;
      let failureAction = actions.ordersFailure;
      let apiCall = getRecentAndUpcomingOrdersFromApi(
        locationId,
        accessToken,
        numberOfPreviousDays,
      );

      if (key === keys.LOAD_MORE_PAST_ORDERS) {
        const specificDays = selectSpecificDays(state);
        const lastEndDate = specificDays.startDate ?? selectLastEndDate(state);
        const endDateStartOfDay = startOfDay(
          parse(lastEndDate, ooeConstants.DATE_TIME_FORMAT.date, new Date()),
        );
        const startDate = format(
          subDays(endDateStartOfDay, numberOfPreviousDays),
          ooeConstants.DATE_TIME_FORMAT.date,
        );
        const endDate = format(
          endDateStartOfDay,
          ooeConstants.DATE_TIME_FORMAT.date,
        );
        apiCall = getAdditionalPastOrdersFromApi(
          locationId,
          accessToken,
          startDate,
          endDate,
        );
        successAction = actions.loadMorePastOrdersSuccess.bind(null, startDate);
        failureAction = actions.loadMorePastOrdersFailure;
      }

      return epicHelper(
        apiCall,
        successAction,
        failureAction,
        epicOptions(store, action$),
      );
    }),
  );

export const GetOrdersForSpecificDays = (action$, store) =>
  action$.pipe(
    ofType(types.GET_ORDERS_FOR_SPECIFIC_DAYS),
    switchMap((action) => {
      const { startDate, endDate } = action;
      const state = store.value;
      const locationId = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getAdditionalPastOrdersFromApi(
          locationId,
          accessToken,
          startDate,
          endDate,
        ),
        actions.ordersForSpecificDaysSuccess,
        actions.ordersForSpecificDaysFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetDataForExcelExtraction = (action$, store) =>
  action$.pipe(
    ofType(types.GET_DATA_FOR_EXCEL_EXTRACTION),
    mergeMap(({ order }) => {
      const state = store.value;
      const locationNumber = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      let getOrderDetails$ = of({});
      //condition for POS orders api call
      if (pathOr('', ['clientId'], order) === 'POS') {
        getOrderDetails$ = lookupPOSOrderDetails(
          order.transId,
          locationNumber,
          accessToken,
        ).execute();
      }
      //condition for other type of orders api call
      if (!(pathOr('', ['clientId'], order) === 'POS')) {
        getOrderDetails$ = lookupOrderDetailsFromApi(
          order.id,
          locationNumber,
          order.cfaId,
          accessToken,
        ).execute();
      }
      return epicHelper(
        getOrderDetails$,
        actions.getDataForExcelExtractionSuccess.bind(null, order),
        actions.getDataForExcelExtractionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const CancelOrder = (action$, store) =>
  action$.pipe(
    ofType(types.CANCEL_ORDER),
    switchMap(({ orderId, cfaId }) => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        cancelOrderAdminApi(orderId, locationNumber, cfaId, accessToken),
        actions.cancelOrderSuccess.bind(null, orderId),
        actions.cancelOrderFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupOrderDetails = (action$, store) =>
  action$.pipe(
    ofType(types.LOOKUP_ORDER_DETAILS),
    switchMap(({ orderId, cfaId }) => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      const isPOSOrder = selectIsPOSOrder(state);
      if (isPOSOrder) {
        return epicHelper(
          lookupPOSOrderDetails(orderId, locationNumber, accessToken),
          actions.lookupOrderDetailsSuccess,
          actions.lookupOrderDetailsFailure,
          epicOptions(store, action$),
        );
      }
      return epicHelper(
        lookupOrderDetailsFromApi(orderId, locationNumber, cfaId, accessToken),
        actions.lookupOrderDetailsSuccess,
        actions.lookupOrderDetailsFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupCustomerDetails = (action$, store) =>
  action$.pipe(
    ofType(types.LOOKUP_CUSTOMER_DETAILS),
    switchMap(({ orderId, cfaId }) => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        lookupCustomerDetailsFromCustomerID(
          orderId,
          locationNumber,
          cfaId,
          accessToken,
        ),
        actions.lookupCustomerDetailsSuccess,
        actions.lookupCustomerDetailsFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ResendPaymentEmail = (action$, store) =>
  action$.pipe(
    ofType(types.RESEND_PAYMENT_EMAIL),
    switchMap(({ orderId, cfaId }) => {
      const state = store.value;
      const apiKey = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        resendPaymentEmail(apiKey, orderId, locationNumber, cfaId),
        actions.resendPaymentEmailSuccess,
        actions.resendPaymentEmailFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  GetOrders,
  CancelOrder,
  LookupOrderDetails,
  LookupCustomerDetails,
  ResendPaymentEmail,
  GetOrdersForSpecificDays,
  GetDataForExcelExtraction,
];
