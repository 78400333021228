// src/ducks/auth.js
import { flatten, map } from 'ramda';
import { createSelector } from 'reselect';

import ooe from '../constants';
import { formatItemName } from '../util/format';
import makeActionCreator from '../util/makeActionCreator';

export const types = {
  MENU_REQUEST: '[Menu] Request',
  MENU_SUCCESS: '[Menu] Success',
  MENU_FAILURE: '[Menu] Failure',
  NUTRITION_SUCCESS: '[Menu] Nutrition Success',
  NUTRITION_FAILURE: '[Menu] Nutrition Failure',
};

export const actions = makeActionCreator({
  getMenu: () => ({ type: types.MENU_REQUEST }),
  menuSuccess: (method, menu) => ({ type: types.MENU_SUCCESS, method, menu }),
  menuFailure: (method, error) => ({ type: types.MENU_FAILURE, method, error }),
  nutritionSuccess: (nutrition) => ({
    type: types.NUTRITION_SUCCESS,
    nutrition,
  }),
  nutritionFailure: (error) => ({ type: types.NUTRITION_FAILURE, error }),
});

export const initialState = {
  isLoading: false,
  error: null,
  nutrition: {},
  [ooe.DELIVERY]: { categories: [], itemGroups: {} },
  [ooe.PICKUP]: { categories: [], itemGroups: {} },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MENU_REQUEST:
      return { ...state, isLoading: true, error: null };

    case types.MENU_SUCCESS: {
      const { method, menu } = action;
      return { ...state, isLoading: false, [method]: menu };
    }

    case types.MENU_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NUTRITION_SUCCESS: {
      const { nutrition } = action;
      return { ...state, nutrition };
    }

    case types.NUTRITION_FAILURE: {
      let { error } = action;
      if (typeof error === 'string') {
        error = { nutrition: error };
      }
      return { ...state, error: { ...state.error, ...error } };
    }

    default:
      return state;
  }
};

export const selectMenu = (state) => state.menu;

/**
 * Lookup items from the itemGroup list
 * @param itemGroupId
 * @param itemGroups
 * @returns {Array}
 */
function lookUpItemGroup(itemGroupId, itemGroups) {
  const group = itemGroups[itemGroupId] || { items: [] };
  return group.items;
}

/**
 * Flatten menu structure to filter out ITEM_GROUPINGS
 * @param items
 * @param itemGroups
 */
function mapMenuItems(items, itemGroups) {
  return flatten(
    map((item) => {
      let foundItems = lookUpItemGroup(item.itemGroupId, itemGroups || {});
      if (item.disabled && item.objectType === 'MODIFIER') {
        return [];
      }
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item?.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
      } else if (item.leadTime) {
        foundItems = foundItems.map((m) => ({
          ...m,
          leadTime: item.leadTime,
        }));
      }

      let menuItems = mapMenuItems(foundItems, itemGroups || {});
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
        let comboItems;
        let sideItems;
        let dessertItems;
        let selectedSide;
        let selectedDessert;
        if (item.meal) {
          comboItems = [...menuItems];

          // TODO this should be resolved from the Menu side
          comboItems.splice(2, 0, comboItems.splice(1, 1)[0]);

          sideItems = [];
          dessertItems = [];

          menuItems = menuItems.reduce((acc, comboItem) => {
            if (
              comboItem.itemGroupType === 'Side' &&
              comboItem.items &&
              comboItem.items.length
            ) {
              selectedSide =
                comboItem.items.find((sideItem) => sideItem.default) ||
                comboItem.items[0];
            }

            if (
              comboItem.itemType === 'DESSERTS_GROUP' &&
              comboItem.items &&
              comboItem.items.length
            ) {
              selectedDessert = comboItem.items[0];
            }

            const comboModifiers = comboItem.items
              .map((comboModifier) => ({
                ...comboModifier,
                comboTag: comboItem.tag,
              }))
              .filter((comboMod) => {
                if (comboMod.comboTag === 'PREMIUM_SIDES') {
                  sideItems.push(comboMod);
                  return false;
                }
                if (comboMod.comboTag === 'DESSERTS_PKGMEALS') {
                  dessertItems.push(comboMod);
                  return false;
                }
                return true;
              });
            return [...acc, ...comboModifiers];
          }, []);
        }
        return {
          ...item, // top level menu item (ex. chicken sandwich, premium box meal)
          name: formatItemName(item.name),
          items: menuItems, // available modifiers (ex. sauces, dressing, multigrain bun)
          selectedSide, // default premium box meal side (ex. superfood side)
          selectedDessert, // default premium box meal dessert (ex. cookie)
          comboItems, // items that make up a combo meal (ex. sandwich, cookie, chips)
          sideItems, // available sides for a premium box meal (ex. superfood side, fruit cup)
          dessertItems, // available desserts for a premium box meal (ex. cookie, brownie)
        };
      }

      return menuItems;
    })(items || []),
  );
}

/**
 * Map out all categories of the menu
 * @param categories
 * @param itemGroups
 */
const mapMenuCategories = (categories = [], itemGroups) =>
  map((cat) => ({
    ...cat,
    items: mapMenuItems(cat.items, itemGroups),
  }))(categories);

export const selectCombinedMenu = (method = '', menu = {}) => {
  const selectedMenu = menu[method] || {};
  return mapMenuCategories(selectedMenu.categories, selectedMenu.itemGroups);
};

export const selectNutrition = createSelector(
  selectMenu,
  (menu) => menu.nutrition,
);
