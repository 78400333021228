import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { getFeatureFlags } from '../apis';

let featureFlags = {};

const initialize = () => {
  featureFlags = {};
  return getFeatureFlags()
    .execute()
    .pipe(
      switchMap((res) => {
        featureFlags = res.allFlags;
        return of(null);
      }),
    );
};

const variation = (featureFlag) => !!featureFlags[featureFlag];

export default {
  initialize,
  variation,
};
