/* eslint-disable @typescript-eslint/no-shadow */ //TODO: Fix shadowed vars below to get rid of these warnings
/* eslint-disable react/forbid-prop-types */
import { TextField } from '@cfa/react-components';
import { Box, Flex, Select } from '@cfacorp/cowponents';
import { addMinutes, format, isAfter, isBefore, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { path, pathOr } from 'ramda';
import { useEffect, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import constants from '../../constants';
import { formatTimePickerTime, generateTimeSlots } from '../../util/format';
import { validateDetails, validateGuestCount } from '../../util/validate';
import DatePickerInput from '../DatePicker/DatePicker';
import Disclaimer from '../Disclaimer/Disclaimer';
import LeadTimeWarning from '../LeadTimeWarning/LeadTimeWarning';
import SelectCateringOccasion from '../SelectCateringOccasion/SelectCateringOccasion';
import ValidatedField from './ValidatedField';
import wrapComponentFormField from './wrapComponentFormField';

let serviceChannelHours;
let timeSlotsArr = [];

const getTimeSlots = () => timeSlotsArr;

function isInBlackoutWindow(
  slotTime,
  endSlotTime,
  blackoutStart,
  blackoutMinutes,
) {
  const blackoutEndTime = addMinutes(blackoutStart, blackoutMinutes);
  return (
    isBefore(slotTime, blackoutEndTime) && isAfter(endSlotTime, blackoutStart)
  );
}

function fillTimeSlotsArr(
  openTime,
  endTime,
  blackoutStart = parse('00:00', constants.DATE_TIME_FORMAT.time, new Date()),
  blackoutMinutes = 0,
  nextSlot = 15,
) {
  timeSlotsArr = [];
  let currentSlotOpenTime = openTime;
  while (!isAfter(currentSlotOpenTime, endTime)) {
    const currentSlotEndTime = addMinutes(currentSlotOpenTime, nextSlot);
    if (
      !isInBlackoutWindow(
        currentSlotOpenTime,
        currentSlotEndTime,
        blackoutStart,
        blackoutMinutes,
      )
    ) {
      timeSlotsArr.push(
        format(currentSlotOpenTime, constants.DATE_TIME_FORMAT.time),
      );
    }
    currentSlotOpenTime = addMinutes(currentSlotOpenTime, nextSlot);
  }
}

function getServiceChannelHours(
  hoursOfOperation,
  specialEvents = [],
  pickedDate,
) {
  const date = format(pickedDate, constants.DATE_TIME_FORMAT.date);
  const dayOfWeek = format(pickedDate, 'EEEE').toLocaleLowerCase();
  const operationType = path([dayOfWeek, 'operationType'], hoursOfOperation);
  const operatingInterval = path(
    [dayOfWeek, 'operatingInterval'],
    hoursOfOperation,
  );
  const holidays = path(['holidays'], hoursOfOperation);
  const holidayDay = holidays?.filter((h) => h.holidayDate === date);

  const specialEventDay = specialEvents?.find((s) => {
    const sd = parse(s.startDate, constants.DATE_TIME_FORMAT.date, new Date());
    const ed = parse(s.endDate, constants.DATE_TIME_FORMAT.date, new Date());
    const isSpecialEvent =
      !isAfter(sd, pickedDate) && !isBefore(ed, pickedDate);
    return !!isSpecialEvent;
  });

  if (holidayDay?.length > 0) {
    const [holiday] = holidayDay;
    const { holidayHours } = holiday;
    const { operationType, operatingInterval } = holidayHours;

    if (operationType !== 'closed') {
      const { openTime, durationInMinutes } = operatingInterval;
      const parsedOpenTime = parse(
        openTime,
        constants.DATE_TIME_FORMAT.time,
        new Date(),
      );
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      fillTimeSlotsArr(parsedOpenTime, endTime);
    } else {
      timeSlotsArr = [];
    }
  } else if (specialEventDay) {
    const { operationType, operatingInterval } =
      specialEventDay.specialEventHours;

    if (operationType === 'standardHours') {
      const { openTime, durationInMinutes } = operatingInterval;
      const parsedOpenTime = parse(
        openTime,
        constants.DATE_TIME_FORMAT.time,
        new Date(),
      );
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      fillTimeSlotsArr(parsedOpenTime, endTime);
    } else {
      timeSlotsArr = [];
    }
  } else if (operationType === 'standardHours') {
    const { openTime, durationInMinutes } = operatingInterval;
    const parsedOpenTime = parse(
      openTime,
      constants.DATE_TIME_FORMAT.time,
      new Date(),
    );
    const endTime = addMinutes(parsedOpenTime, durationInMinutes);
    const blackoutHours = pathOr(
      { openTime: '00:00', durationInMinutes: 0 },
      [dayOfWeek, 'blackoutHours'],
      hoursOfOperation,
    );
    fillTimeSlotsArr(
      parsedOpenTime,
      endTime,
      parse(
        blackoutHours.openTime,
        constants.DATE_TIME_FORMAT.time,
        new Date(),
      ),
      blackoutHours.durationInMinutes,
    );
  } else if (operationType === 'open24Hours') {
    fillTimeSlotsArr(
      parse('00:00', constants.DATE_TIME_FORMAT.time, new Date()),
      parse('23:45', constants.DATE_TIME_FORMAT.time, new Date()),
    );
  } else {
    timeSlotsArr = [];
  }

  return timeSlotsArr.map((t) => ({ time: t, available: true }));
}

function renderAvailableTimes(
  times,
  bypassBusinessRules,
  dateIsToday,
  timeZone,
) {
  const timeSlots = bypassBusinessRules
    ? generateTimeSlots(dateIsToday, timeZone)
    : times;
  return timeSlots?.map((timeSlot) => {
    let formattedTime = formatTimePickerTime(timeSlot.time);
    const timeUnavailable = !timeSlot.available;
    if (timeUnavailable) {
      formattedTime += ' (Time is currently unavailable)';
    }
    return (
      <option
        className="time-option"
        disabled={timeUnavailable}
        key={formattedTime}
        value={timeSlot.time}
      >
        {formattedTime}
      </option>
    );
  });
}

function timesPlaceholder(timeSlotsData, bypassBusinessRules) {
  if (bypassBusinessRules) {
    return 'Select Time';
  }
  if (!timeSlotsData.length) {
    return 'There are no available times for this order date.';
  }
  return 'Select Time';
}

export function DetailsForm({
  availableDates,
  selectedDate,
  change,
  dateChanged,
  timeChanged,
  availableDatesLoading,
  guestCountChanged,
  bypassBusinessRules,
  dateIsToday,
  timeZone,
  leadTimeWarning,
  hoursOfOperation,
  specialEvents,
  cateringReason: cateringOccasionValue,
  timeShouldClear,
  resetTimeFormValues,
}) {
  /* Start with why:
     Because redux form is dumb, and dispatches the onChange event before
     the change action creator, we were getting unexpected behavior
     when trying to predict state when the dateChanged or timeChanged
     actions had been dispatched. This makes it much more predictable
     as the state is updated before the action.

     TODO: remove redux-form
   */
  useEffect(() => {
    if (timeShouldClear) {
      resetTimeFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeShouldClear]);

  const handleDateChange = (date) => {
    change('date', date);
    dateChanged(date);
    serviceChannelHours = getServiceChannelHours(
      hoursOfOperation,
      specialEvents,
      date,
    );
  };

  const handleTimeChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    change('time', value);
    timeChanged();
  };

  const handleCateringOccasion = (event) => {
    const { value } = event.target;
    change('cateringReason', value);
  };

  const wrappedTextField = useMemo(
    () => wrapComponentFormField(TextField),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wrapComponentFormField, TextField],
  );

  return (
    <StyledDetailsForm>
      {!bypassBusinessRules && (
        <Disclaimer>
          Unavailable dates and times are blocked due to settings in DOP
        </Disclaimer>
      )}
      <Flex display="flex" flexWrap="wrap" m="0 0 20px 0">
        <Box width={[1, 1 / 2]}>
          <Field
            availableDates={availableDates}
            bypassBusinessRules={bypassBusinessRules}
            className="date"
            component={DatePickerInput}
            loading={bypassBusinessRules ? false : availableDatesLoading}
            name="date"
            onChange={handleDateChange}
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          {(selectedDate || bypassBusinessRules) && (
            <Field
              className="time"
              component={wrapComponentFormField(Select)}
              disabled={bypassBusinessRules ? false : !timeSlotsArr.length}
              m="10px 10px 0 10px"
              name="time"
              onChange={handleTimeChange}
              placeholder={timesPlaceholder(timeSlotsArr, bypassBusinessRules)}
              type="time"
            >
              {renderAvailableTimes(
                serviceChannelHours,
                bypassBusinessRules,
                dateIsToday,
                timeZone,
              ) || (
                <option disabled>
                  There are no available times for this order date.
                </option>
              )}
            </Field>
          )}
          <div className="lead-time-wrapper">
            <LeadTimeWarning message={leadTimeWarning.message} />
          </div>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <Field
            className="guestCount"
            component={ValidatedField}
            label="Guest Count"
            m="0"
            name="guestCount"
            normalize={validateGuestCount}
            onChange={guestCountChanged}
            onKeyDown={(e) =>
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            } // https://stackoverflow.com/a/70084470
            placeholder="Guest Count"
            type="number"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <SelectCateringOccasion
            cateringOccasionValue={cateringOccasionValue}
            handleCateringOccasion={handleCateringOccasion}
          />
        </Box>
      </Flex>
      <Flex fontSize="12px" m="10px 10px 0 10px" width="calc(100% - 14px)">
        Any special instructions written here will show on the email receipt the
        customer receives upon order submission.
      </Flex>
      <Field
        className="specials"
        component={wrappedTextField}
        maxLength={144}
        multiline
        name="specialInstructions"
        placeholder="Special Instructions"
        type="notes"
      />
    </StyledDetailsForm>
  );
}

const StyledDetailsForm = styled(Box)`
  & .specials {
    width: calc(100% - 14px);
    max-width: unset;
    margin-left: 10px;
  }

  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.outline};
    height: 50px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 14px);
    appearance: none;
    padding-left: 10px;
  }

  & input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    opacity: 0;
  }

  & input::placeholder {
    color: ${(props) => props.theme.colors.outline};
  }

  & input[type='number']:hover::-webkit-inner-spin-button,
  input[type='number']:hover::-webkit-outer-spin-button,
  input[type='number']:focus::-webkit-inner-spin-button,
  input[type='number']:focus::-webkit-outer-spin-button {
    opacity: 1;
  }

  & .lead-time-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    @media (max-width: ${(props) => props.theme.phone}) {
      position: relative;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

DetailsForm.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  dateChanged: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  availableDatesLoading: PropTypes.bool,
  guestCountChanged: PropTypes.func.isRequired,
  bypassBusinessRules: PropTypes.bool,
  dateIsToday: PropTypes.bool,
  timeChanged: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  leadTimeWarning: PropTypes.objectOf(PropTypes.string).isRequired,
  hoursOfOperation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  specialEvents: PropTypes.array,
  cateringReason: PropTypes.string,
  timeShouldClear: PropTypes.bool,
  resetTimeFormValues: PropTypes.func,
};

DetailsForm.defaultProps = {
  availableDatesLoading: true,
  bypassBusinessRules: false,
  dateIsToday: false,
  hoursOfOperation: {},
  specialEvents: [],
  cateringReason: '',
  timeShouldClear: false,
  resetTimeFormValues: () => {},
};

export const exportsForTesting = {
  isInBlackoutWindow,
  getTimeSlots,
  fillTimeSlotsArr,
  getServiceChannelHours,
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.DETAILS,
  destroyOnUnmount: false,
  validate: validateDetails,
  initialValues: { date: '' },
})(DetailsForm);
