import { Input } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const DeliveryTipsCustomAmount = (props) => {
  const { isSelect, onBlurHandler, selectedCustomTipValue } = props;

  return (
    <StyledCustomTipAmount
      isSelect={isSelect}
      selectedCustomTipValue={selectedCustomTipValue}
    >
      <Input
        bg={isSelect || selectedCustomTipValue !== 0 ? '#004F71' : '#fff'}
        borderColor="#004F71"
        className="custom-amount-input"
        color={isSelect || selectedCustomTipValue !== 0 ? '#fff' : '#004F71'}
        defaultValue={
          selectedCustomTipValue !== 0 ? selectedCustomTipValue : ''
        }
        height="35px"
        id="customTip"
        m="5px"
        onBlur={onBlurHandler}
        p="1px 1px 1px 20px"
        placeholder="Custom Tip"
        type="number"
        width="calc(100% - 10px)"
      />
    </StyledCustomTipAmount>
  );
};

DeliveryTipsCustomAmount.propTypes = {
  isSelect: PropTypes.bool,
  onBlurHandler: PropTypes.func,
  selectedCustomTipValue: PropTypes.number,
};

DeliveryTipsCustomAmount.defaultProps = {
  isSelect: false,
  onBlurHandler: () => {},
  selectedCustomTipValue: 0,
};

export const StyledCustomTipAmount = styled('div')`
  position: relative;
  & input {
    text-align: center;
  }
  &::after {
    content: '$';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    color: ${(props) =>
      props.isSelect || props.selectedCustomTipValue !== 0
        ? '#ffff'
        : props.theme.colors.primary};
  }
`;

export default DeliveryTipsCustomAmount;
