import 'react-app-polyfill/ie11';
import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import ConnectedApp from './App';
import BugsnagErrorBoundary from './components/BugsnagErrorBoundary/BugsnagErrorBoundary';
import UnrecoverableError from './components/ErrorMessage/UnrecoverableError';
import ooeConstants from './constants';
import initializeStore from './initializeStore';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { setupAmplitude } from './services/amplitude';
import {
  initializeGetBugSnagStateData,
  leaveBreadcrumb,
  setupBugsnag,
} from './services/bugsnag';
import FeatureFlagService from './services/featureFlags';
import getBugSnagStateData from './util/bugSnagStateData';
import { BrowserRouter } from './util/history';
import redirectToHttpsIfNecessary from './util/redirectToHttpsIfNecessary';
import ReduxStore from './util/reduxStore';

redirectToHttpsIfNecessary({
  location: window.location,
  redirectToUrl: (url) => {
    window.location.href = url;
  },
});

const store = initializeStore();
ReduxStore.store = store;

try {
  FeatureFlagService.initialize(ooeConstants.URL.CMT_CONFIG).subscribe();
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn('FeatureFlagService.initialize error', e);
}

// set up bugsnag
initializeGetBugSnagStateData(getBugSnagStateData);
setupBugsnag(store);

leaveBreadcrumb('Application Startup', {
  message: 'Application initializing',
});

setupAmplitude();

const render = (Component) => {
  const root = createRoot(document.getElementById('root'));
  return root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <BugsnagErrorBoundary FallbackComponent={UnrecoverableError}>
      <Provider store={store}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </Provider>
    </BugsnagErrorBoundary>,
  );
};

render(ConnectedApp);

unregisterServiceWorker();
