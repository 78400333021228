import { OktaAuth } from '@okta/okta-auth-js';
import { EMPTY, from } from 'rxjs';

import ooeConstants from '../constants';
import { locationErrorMessages } from '../util/customerErrorMessages';
import handleErrors from '../util/handleErrors';
import { isRedirectToHttpsNecessary } from '../util/redirectToHttpsIfNecessary';
import requestFactory from '../util/requestFactory';
import { leaveBreadcrumb, notifyBugsnag } from './bugsnag';

const cfaTokenKey = ooeConstants.LOCAL_STORAGE_CFA_KEY;
const oktaTokenKey = ooeConstants.LOCAL_STORAGE_OKTA_KEY;

const tokenToPayload = (token) => {
  const payload = token.split('.')[1];
  return JSON.parse(atob(payload));
};

const saveToLocalStorage = (token, key = cfaTokenKey) => {
  localStorage.setItem(key, JSON.stringify(token));
  return token;
};

export function refreshOktaToken(refreshToken) {
  const config = ooeConstants.OKTA_CONFIG;
  const tokenUrl = `${config.issuer}/v1/token`;
  const body = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: config.clientId,
    refresh_token: refreshToken,
    scope: 'openid email offline_access',
  });

  return fetch(tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  })
    .then(handleErrors)
    .then((data) => {
      const payload = tokenToPayload(data.access_token);
      const mappedData = {
        ...payload,
        accessToken: data.access_token,
      };
      saveToLocalStorage(mappedData, cfaTokenKey);
      return mappedData;
    });
}

export function getOktaToken({
  authClientFactory = (config) => new OktaAuth(config),
  location = window.location,
} = {}) {
  localStorage.removeItem(cfaTokenKey);
  localStorage.removeItem(oktaTokenKey);
  localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
  localStorage.removeItem(ooeConstants.EULA);

  const config = ooeConstants.OKTA_CONFIG;
  const authClient = authClientFactory({
    ...config,
    scopes: ['openid', 'email', 'offline_access'],
  });

  leaveBreadcrumb('Get Okta Token', {
    config,
  });

  const request = authClient.token
    .parseFromUrl()
    .then((response) => {
      const { accessToken, idToken } = response.tokens;

      // save the pieces we want to save off (formerly obtained from exchange server)
      const tokenData = {
        iss: accessToken?.claims?.iss, // issuer
        jti: accessToken?.claims?.jti, // unique token ID
        iat: accessToken?.claims?.iat, // issued at time
        exp: accessToken?.claims?.exp, // expiration time
        sub: accessToken?.claims?.sub, // subject (user identifier)
        cfa_guid: accessToken?.claims?.cfa_guid,
        family_name: accessToken?.claims?.family_name,
        nickname: accessToken?.claims?.nickname,
        email: accessToken?.claims?.email,
        cfa_aud: accessToken?.claims?.cfa_aud,
        cfa_perms: accessToken?.claims?.cfa_perms,
        accessToken: accessToken?.accessToken,
        refreshToken: response.tokens.refreshToken?.refreshToken,
      };

      saveToLocalStorage(tokenData, cfaTokenKey);
      saveToLocalStorage(idToken, oktaTokenKey);
      return idToken;
    })
    .catch((error) => {
      leaveBreadcrumb('Get Okta Token Exception Handler', {
        config,
        error,
      });

      if (error.errorCode !== ooeConstants.OKTA_INVALID_ERROR) {
        if (isRedirectToHttpsNecessary(location)) {
          leaveBreadcrumb('Get Okta Token RedirectToHttpsNecessary is true', {
            location,
          });
          return EMPTY;
        }
        leaveBreadcrumb(
          'Get Okta Token returning authClient.token.getWithRedirect',
          {
            location,
          },
        );
        return authClient.token.getWithRedirect();
      }

      notifyBugsnag(ooeConstants.BUGSNAG_ERRORCLASS_OKTA, {
        context: 'Get Okta Token',
        info: {
          config,
          error,
        },
      });
      throw new Error(error);
    });

  return request;
}

export function logoutUser() {
  const config = ooeConstants.OKTA_CONFIG;
  const authClient = new OktaAuth(config);
  authClient.tokenManager.remove(cfaTokenKey);
  localStorage.removeItem(cfaTokenKey);
  localStorage.removeItem(ooeConstants.EULA);

  leaveBreadcrumb('Logout User', {
    config,
  });

  const signOutRequest = authClient
    .signOut()
    .then(() => {
      window.location.href = ooeConstants.LOGOUT_LINK;
    })
    .catch((error) => {
      notifyBugsnag(ooeConstants.BUGSNAG_ERRORCLASS_OKTA, {
        context: 'Logout User',
        info: {
          config,
          error,
        },
      });
      // eslint-disable-next-line no-console
      console.error(error);
      window.location.href = ooeConstants.LOGOUT_LINK;
    });

  return from(signOutRequest);
}

export function lookupLocationsByNumber(locationNumbers, accessToken) {
  const locString = locationNumbers.join(',');
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/search?locationNumbers=${locString}`;

  const mapper = (locations) => {
    if (locations && locations.length > 0) {
      return locations;
    }
    throw new Error(locationErrorMessages.NO_LOCATIONS);
  };
  return requestFactory({
    url,
    mapper,
    auth: {
      type: 'JWTBearer',
      apiKey: accessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Lookup Location by Number',
      info: {
        locationNumbers,
      },
    },
  });
}

export function fetchVcaLocations() {
  const baseUrl = ooeConstants.URL.CMT_CONFIG;
  const url = `${baseUrl}/1.0/storeNumbers`;
  const mapper = (jsonObj) => {
    const { VCAMiamiStoreNumbers } = jsonObj;
    return [...VCAMiamiStoreNumbers];
  };

  leaveBreadcrumb('Fetch VCA Locations', {
    url,
  });

  // TODO: move over to an observable fetch call
  const request = fetch(url).then(handleErrors).then(mapper);
  return from(request);
}

export function getStoreNamesAndNumbersFromApi(accessToken) {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/locationSummary`;

  return requestFactory({
    url,
    auth: {
      type: 'JWTBearer',
      apiKey: accessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Get Store Names And Numbers',
    },
  });
}

export function checkMessagesOnLoad(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return requestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Check Messages On Load for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Check Messages On Load',
      info: {
        type,
      },
    },
  });
}

export function getMessageText(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}`;
  return requestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Get Message Text for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Get Message Text',
      info: {
        type,
      },
    },
  });
}

export function addUserToMessageAcknowledgement(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return requestFactory({
    url,
    method: 'POST',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Add User To Message Acknowledgement for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Add User To Message Acknowledgement',
      info: {
        type,
      },
    },
  });
}

export default {
  getOktaToken,
  refreshOktaToken,
  logoutUser,
  lookupLocationsByNumber,
  fetchVcaLocations,
  checkMessagesOnLoad,
  getMessageText,
  addUserToMessageAcknowledgement,
  getStoreNamesAndNumbersFromApi,
};
