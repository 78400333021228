import { constructNow, isBefore } from 'date-fns';
import { pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  switchMap,
  tap,
} from 'rxjs/operators';

import ooeConstants from '../constants';
import { types as orderTypes } from '../reducers/order';
import {
  actions,
  doesLocationNumberExist,
  isDeveloperAudience,
  selectAccessToken,
  selectAccessTokenExpirationDate,
  selectDefaultLocation,
  selectRefreshToken,
  selectUserLocationNumbers,
  types,
} from '../reducers/user';
import {
  addUserToMessageAcknowledgement,
  checkMessagesOnLoad,
  fetchVcaLocations,
  getMessageText,
  getOktaToken,
  getStoreNamesAndNumbersFromApi,
  logoutUser,
  lookupLocationsByNumber,
  refreshOktaToken,
} from '../services/userApi';
import { processOktaToken } from '../util/authTokens';
import history from '../util/history';
import epicHelper, { epicOptions } from '../util/myEpicHelper';

export const GetTokenFromStorage = (action$) =>
  action$.pipe(
    ofType(types.GET_TOKEN_FROM_STORAGE),
    switchMap(() => {
      const token = JSON.parse(
        localStorage.getItem(ooeConstants.LOCAL_STORAGE_CFA_KEY),
      );
      return token
        ? of(actions.getTokenFromStorageSuccess(token))
        : of(actions.getOktaToken());
    }),
  );

export const GetTokenFromStorageSuccess = (action$, store) =>
  action$.pipe(
    ofType(types.GET_TOKEN_FROM_STORAGE_SUCCESS),
    switchMap(() => {
      const exp = selectAccessTokenExpirationDate(store.value);
      const isExpired = isBefore(new Date(exp), constructNow(new Date()));

      if (isExpired) {
        return of(actions.refreshOktaToken());
      }
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
      return of(actions.processOktaTokenSuccess(store.value.user));
    }),
  );

export const AuthUser = (action$) =>
  action$.pipe(
    ofType(types.GET_OKTA_TOKEN, types.REFRESH_TOKEN_FAILURE),
    switchMap(() =>
      getOktaToken()
        .then(actions.getOktaTokenSuccess)
        .catch(actions.getOktaTokenFailure),
    ),
  );

export const ProcessOktaToken = (action$) =>
  action$.pipe(
    ofType(types.GET_OKTA_TOKEN_SUCCESS),
    switchMap(() =>
      processOktaToken()
        .then(actions.processOktaTokenSuccess)
        .catch(actions.processOktaTokenFailure),
    ),
  );

export const RefreshToken = (action$, store) =>
  action$.pipe(
    ofType(types.REFRESH_TOKEN),
    switchMap(() => {
      const state = store.value;
      const refreshTokenToUse = selectRefreshToken(state);
      return refreshOktaToken(refreshTokenToUse)
        .then(actions.refreshTokenSuccess)
        .catch(actions.refreshTokenFailure);
    }),
  );

export const CheckMessagesOnLoad = (action$, store) =>
  action$.pipe(
    ofType(types.PROCESS_OKTA_TOKEN_SUCCESS, types.REFRESH_TOKEN_SUCCESS),
    filter(() => !window.Cypress),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      if (localStorage.getItem(ooeConstants.EULA)) {
        return of(
          actions.addUserToAcknowledgementSuccessStorage(ooeConstants.EULA),
        );
      }
      return epicHelper(
        checkMessagesOnLoad(accessToken, ooeConstants.EULA),
        actions.messageAcknowledgedSuccess(ooeConstants.EULA),
        actions.messageAcknowledgedFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const GetMessageText = (action$, store) =>
  action$.pipe(
    ofType(types.MESSAGE_ACKNOWLEDGED_FAILURE),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getMessageText(accessToken, ooeConstants.EULA),
        actions.messageTextSuccess(ooeConstants.EULA),
        actions.messageTextFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const AddUserToMessageAcknowledgement = (action$, store) =>
  action$.pipe(
    ofType(types.ADD_USER_TO_ACKNOWLEDGEMENT, orderTypes.DISMISS_ERROR),
    filter(({ key }) => key === ooeConstants.EULA),
    switchMap(({ key }) => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        addUserToMessageAcknowledgement(accessToken, key),
        () => actions.addUserToAcknowledgementSuccess(key),
        (error) => actions.messageAcknowledgedFailure(key, error),
        epicOptions(store, action$),
      );
    }),
  );

export const ShowHardcodedMessageAcknowledgement = (action$) =>
  action$.pipe(
    ofType(types.MESSAGE_TEXT_FAILURE),
    filter(() => {
      const cmtEula = localStorage.getItem(ooeConstants.EULA);
      return !cmtEula;
    }),
    switchMap(() => of(actions.showHardcodedEulaText())),
  );

export const LookupSingleLocation = (action$, store) =>
  action$.pipe(
    ofType(types.LOOKUP_LOCATION),
    filter((action) => {
      const { location } = action;
      const state = store.value;
      return !doesLocationNumberExist(state, location);
    }),
    switchMap((action) => {
      const { location } = action;
      const locations = [location];
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locations, accessToken),
        actions.lookupLocationSuccess,
        actions.lookupLocationFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupUserLocations = (action$, store) =>
  action$.pipe(
    ofType(types.PROCESS_OKTA_TOKEN_SUCCESS, types.REFRESH_TOKEN_SUCCESS),
    switchMap((action) => {
      const state = store.value;
      const CMT = pathOr({}, ['user', 'cfa_perms', 'CMT'], action);
      const { user } = action;
      const cfaAud = user.cfa_aud;
      let locationNumbers = selectUserLocationNumbers(state);

      const { ADMIN, LOGIN, VCA, VCA_BYPASS } = CMT;

      if ((ADMIN || VCA || VCA_BYPASS) && locationNumbers[0] === '00000') {
        return fetchVcaLocations().pipe(
          catchError((err) => of(actions.getUserLocationsFailure(err))),
          switchMap((locations) => {
            const storedLoc = selectDefaultLocation(state);
            let locationList = locations;
            if (locationList.error) {
              return of(actions.throwFullScreenError());
            }
            if (storedLoc) {
              locationList.unshift(storedLoc);
              locationList = [...new Set(locationList)];
            }

            const accessToken = selectAccessToken(state);
            return epicHelper(
              lookupLocationsByNumber(locationList, accessToken),
              actions.getUserLocationsSuccess,
              actions.getUserLocationsFailure,
              epicOptions(store, action$),
            );
          }),
        );
      }
      // let devs with LOGIN permission access multiple labs :)
      if (
        LOGIN &&
        locationNumbers[0] === '00000' &&
        isDeveloperAudience(cfaAud)
      ) {
        locationNumbers = ooeConstants.DEFAULT_STORES;
      }
      if (LOGIN && locationNumbers[0] === '00000') {
        locationNumbers = [ooeConstants.PROD_PILOT_LAB];
      }
      const locations = locationNumbers;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locations, accessToken),
        actions.getUserLocationsSuccess,
        actions.throwFullScreenError,
        epicOptions(store, action$),
      );
    }),
  );

export const SetBBR = (action$) =>
  action$.pipe(
    ofType(types.PROCESS_OKTA_TOKEN_SUCCESS, types.REFRESH_TOKEN_SUCCESS),
    switchMap((action) => {
      const CMT = pathOr([], ['user', 'cfa_perms', 'CMT'], action);
      const permissions = Object.keys(CMT);
      const isVca = [
        ooeConstants.PERMISSIONS.VCA,
        ooeConstants.PERMISSIONS.VCA_BYPASS,
      ].some((item) => permissions.includes(item));
      return of(actions.setBBRForVCA(isVca));
    }),
  );

export const LogoutUser = (action$) =>
  action$.pipe(
    ofType(types.LOGOUT_USER),
    tap(() => {
      logoutUser();
    }),
    ignoreElements(),
  );

export const fullScreenError = (action$) =>
  action$.pipe(
    ofType(types.FULL_SCREEN_ERROR),
    tap(() => history.push({ pathname: '/error' })),
    ignoreElements(),
  );

export const GetStoreNumbersAndNames = (action$, store) =>
  action$.pipe(
    ofType(types.PROCESS_OKTA_TOKEN_SUCCESS, types.REFRESH_TOKEN_SUCCESS),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getStoreNamesAndNumbersFromApi(accessToken),
        actions.getStoreNumbersAndNamesSuccess,
        actions.getStoreNumbersAndNamesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  AddUserToMessageAcknowledgement,
  AuthUser,
  CheckMessagesOnLoad,
  ProcessOktaToken,
  fullScreenError,
  GetMessageText,
  GetTokenFromStorage,
  GetTokenFromStorageSuccess,
  LogoutUser,
  LookupSingleLocation,
  LookupUserLocations,
  RefreshToken,
  SetBBR,
  GetStoreNumbersAndNames,
  ShowHardcodedMessageAcknowledgement,
];
