/* eslint-disable react/forbid-prop-types */
import { Section, SectionHeader } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';

import EventSummary from '../EventSummary/EventSummary';
import OrderSummary from '../OrderSummary/OrderSummary';
import Totals from '../Totals/Totals';

const OrderInformation = (props) => {
  const {
    eventDetails,
    deliveryAddress,
    guestDetails,
    destination,
    eventSummaryValid,
    addressValidated,
    restaurantContactDetails,

    cartItems,

    subTotal,
    taxAndTotal,
    orderLoading,
    deliveryTip,
    deliveryTipIsDisplayed,
    deliveryTipLoading,
    secondaryContactDetails,
    cateringReason,
    promoFreeActive,
    isInvoicePage,
    specialInstructionMessage,
    isEditMode,
    paperGoodsRequired,
    showDefaultPaperGoodsMessage,
    taxAmount,
  } = props;

  return (
    <>
      <Section>
        <SectionHeader>Event Summary</SectionHeader>
        <EventSummary
          addressValidated={addressValidated}
          cateringReason={cateringReason}
          deliveryAddress={deliveryAddress}
          destination={destination}
          eventDetails={eventDetails}
          eventSummaryValid={eventSummaryValid}
          guestDetails={guestDetails}
          isEditMode={isEditMode}
          isInvoicePage={isInvoicePage}
          paperGoodsRequired={paperGoodsRequired}
          restaurantContactDetails={restaurantContactDetails}
          secondaryContactDetails={secondaryContactDetails}
          showDefaultPaperGoodsMessage={showDefaultPaperGoodsMessage}
          specialInstructionMessage={specialInstructionMessage}
        />
      </Section>
      <Section>
        <SectionHeader>Order Summary</SectionHeader>
        <OrderSummary cartItems={cartItems} />
        <Totals
          deliveryTip={deliveryTip}
          deliveryTipIsDisplayed={deliveryTipIsDisplayed}
          deliveryTipLoading={deliveryTipLoading}
          isLoading={orderLoading}
          promoFreeActive={promoFreeActive}
          subTotal={subTotal}
          taxAmount={taxAmount}
          taxAndTotal={taxAndTotal}
        />
      </Section>
    </>
  );
};

OrderInformation.propTypes = {
  eventDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  deliveryAddress: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  guestDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  destination: PropTypes.string.isRequired,
  eventSummaryValid: PropTypes.bool,
  addressValidated: PropTypes.bool,
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  cartItems: PropTypes.arrayOf(PropTypes.object),
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: PropTypes.bool,
  deliveryTip: PropTypes.objectOf(PropTypes.number),
  deliveryTipIsDisplayed: PropTypes.bool,
  deliveryTipLoading: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  cateringReason: PropTypes.string,
  secondaryContactDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  isInvoicePage: PropTypes.bool,
  specialInstructionMessage: PropTypes.string,
  isEditMode: PropTypes.bool,
  paperGoodsRequired: PropTypes.bool,
  showDefaultPaperGoodsMessage: PropTypes.bool,
  taxAmount: PropTypes.string,
};

OrderInformation.defaultProps = {
  eventDetails: {},
  deliveryAddress: {},
  guestDetails: {},
  eventSummaryValid: false,
  addressValidated: false,
  restaurantContactDetails: {},
  cartItems: [],
  subTotal: '',
  taxAndTotal: '',
  orderLoading: false,
  deliveryTip: {},
  deliveryTipIsDisplayed: false,
  deliveryTipLoading: false,
  promoFreeActive: false,
  cateringReason: '',
  secondaryContactDetails: {},
  isInvoicePage: false,
  specialInstructionMessage: '',
  isEditMode: false,
  paperGoodsRequired: false,
  showDefaultPaperGoodsMessage: false,
  taxAmount: '',
};

export default OrderInformation;
