import ooeConstants from '../constants';
import { notifyBugsnag } from '../services/bugsnag';

const cfaTokenKey = ooeConstants.LOCAL_STORAGE_CFA_KEY;

export function processOktaToken() {
  return new Promise((resolve, reject) => {
    try {
      const tokenData = JSON.parse(localStorage.getItem(cfaTokenKey));
      if (!tokenData) {
        throw new Error('Token data is missing or invalid');
      }
      resolve(tokenData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      notifyBugsnag(ooeConstants.BUGSNAG_ERRORCLASS_OKTA, {
        context: 'Process Okta Token',
        info: {
          config: ooeConstants.OKTA_CONFIG,
          error,
        },
      });
      reject(new Error(error));
    }
  });
}
