/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ooeConstants from '../../constants';
import { getNutritionValues } from '../../util/format';

const Nutrition = ({ nutrition, servingSize, className }) => (
  <div className={className}>
    <div className="title">Nutrition Facts</div>
    <div className="values-disclaimer" data-cy="serving-size">
      {`Nutritional values are ${servingSize}`}
    </div>
    <table className="nutrition-table">
      <tbody>
        <tr className="serving-disclaimer">
          <td>Amount Per Serving</td>
        </tr>
        {ooeConstants.NUTRITION_PROPERTIES.map((property) => (
          <tr data-cy={property.key} key={property.key}>
            <td>
              <span className={property.bold ? 'bold' : ''}>
                {property.name}
              </span>
              {` ${getNutritionValues(nutrition, property.key)}${property.unit}`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <table className="vitamins-table">
      <tbody>
        <tr>
          {ooeConstants.VITAMINS.map((vitamin) => (
            <td
              key={vitamin.key}
            >{`${vitamin.name} ${getNutritionValues(nutrition, vitamin.key)}%`}</td>
          ))}
        </tr>
        <tr>
          {ooeConstants.MINERALS.map((mineral) => (
            <td
              key={mineral.key}
            >{`${mineral.name} ${getNutritionValues(nutrition, mineral.key)}%`}</td>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
);

const StyledNutrition = styled(Nutrition)`
  background-color: white;
  border: 1px solid black;
  padding: 0.5rem;
  & .title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  & .values-disclaimer {
    padding: 5px 0 10px 0;
  }
  & .nutrition-table,
  .vitamins-table {
    width: 100%;
    font-weight: normal;
    text-align: left;
    border-collapse: collapse;
  }
  & .nutrition-table tr {
    border-top: 1px solid black;
    line-height: 25px;
  }
  & .vitamins-table {
    margin-top: 10px;
  }
  & .serving-disclaimer {
    font: ${(props) => props.theme.smallTextFont};
  }
  & .bold {
    font-weight: bold;
  }
  & .values-disclaimer,
  .nutrition-table tr:last-child {
    border-bottom: 10px solid black;
  }
`;

Nutrition.propTypes = {
  className: PropTypes.string.isRequired,
  servingSize: PropTypes.string,
  nutrition: PropTypes.objectOf(PropTypes.any),
};

Nutrition.defaultProps = {
  nutrition: {},
  servingSize: '',
};

export default StyledNutrition;
