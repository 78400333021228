/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { ascend, prop, propOr, sortWith } from 'ramda';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import down from '../../assets/down.svg';
import up from '../../assets/up.svg';
import ooeConstants from '../../constants';
import * as visitedLocationsManager from '../../services/visitedLocationsManager';

export const UserLocation = (props) => {
  const {
    lookupLocation,
    updateLocation,
    locations,
    selectedLocation,
    locationDropdownDisabled,
    allStoreNamesAndNumbers,
  } = props;

  const [search, setSearch] = useState('');
  const [showLocationDropdown, setShowLocationDropdown] = useState(true);
  const dropdownClassName = showLocationDropdown
    ? 'location-dropdown expand'
    : 'location-dropdown';
  const sort = sortWith([ascend(prop('name'))]);
  let matchedLocations = [];
  const topLocations = visitedLocationsManager.getTopLocations(
    ooeConstants.MAX_NUM_OF_DISPLAYED_LOCATIONS,
  );

  useEffect(() => {
    if (showLocationDropdown && locationDropdownDisabled) {
      setShowLocationDropdown(false);
    }
  }, [locationDropdownDisabled]);

  const handleSearchInputChange = (e) => {
    const s = e.target.value;
    setSearch(s);
  };

  const onUnderMaskClickHandler = () => {
    setShowLocationDropdown(false);
  };

  const getCurrentLocation = () => {
    const currentLocation = locations.filter(
      ({ locationNumber }) => locationNumber === selectedLocation,
    )[0];
    return `${propOr('', 'name', currentLocation)}`;
  };

  const toggleLocationDropdown = () => {
    setShowLocationDropdown((prev) => !prev);
  };

  if (allStoreNamesAndNumbers.length > 0) {
    matchedLocations = sort(
      (search.length > 0 ? allStoreNamesAndNumbers : locations).filter(
        (location) =>
          location.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          location.locationNumber.indexOf(search) >= 0,
      ),
    );
  } else if (allStoreNamesAndNumbers.length === 0) {
    //if storeNamesAndNumbers array is empty, this will list locations which are in location array (loaded locations)
    matchedLocations = sort(
      locations.filter(
        (location) =>
          location.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          location.locationNumber.indexOf(search) >= 0,
      ),
    );
  }

  if (matchedLocations.length > 5) {
    matchedLocations = matchedLocations.splice(0, 5);
  }

  const handleUpdateLocation = (e, name) => {
    const locationNumber = e.target.value;
    if (selectedLocation === locationNumber) {
      setShowLocationDropdown(false);
      return;
    }
    updateLocation(locationNumber);
    setSearch('');
    setShowLocationDropdown(false);

    lookupLocation(locationNumber);
    visitedLocationsManager.updateLocation(locationNumber, name);
  };

  const renderLocationDropdown = () => (
    <div className={dropdownClassName}>
      <input
        className="locations-search"
        data-cy="location-search"
        onChange={handleSearchInputChange}
        placeholder="Search Stores"
        value={search}
      />
      <div className="separator" />
      <ul className="locations-list">
        {matchedLocations.map((location) => (
          <li key={location.locationNumber}>
            <button
              data-cy={location.locationNumber}
              onClick={(e) => handleUpdateLocation(e, location.name)}
              value={location.locationNumber}
            >
              {`${location.name} ${location.locationNumber}`}
            </button>
          </li>
        ))}
        {topLocations.length > 0 && (
          <li className="divider">Frequently used locations:</li>
        )}
        {topLocations.map((location) => (
          <li key={location.locationNumber}>
            <button
              data-cy={location.locationNumber}
              onClick={(e) => handleUpdateLocation(e, location.name)}
              value={location.locationNumber}
            >
              {`${location.name} ${location.locationNumber}`}
            </button>
          </li>
        ))}
      </ul>
      <div
        className="under-mask"
        onClick={onUnderMaskClickHandler}
        role="presentation"
      />
    </div>
  );

  const dropdownArrow = () => {
    if (!locationDropdownDisabled) {
      const arrowSrc = showLocationDropdown ? up : down;
      return <img alt="arrow" className="arrow" src={arrowSrc} />;
    }
    return null;
  };

  return (
    <StyledUserLocation
      blurBackground={showLocationDropdown}
      usePointerForCursor={!locationDropdownDisabled}
    >
      <button
        className="selected-location"
        data-cy="selected-location"
        disabled={locationDropdownDisabled}
        onClick={toggleLocationDropdown}
      >
        {getCurrentLocation()}
        {dropdownArrow()}
      </button>
      {renderLocationDropdown()}
    </StyledUserLocation>
  );
};

const slideDown = keyframes`
  0% { transform: translateY(-100%); visibility: visible; }
  100% { transform: none; }
`;

const slideUp = keyframes`
  0% { transform: none; visibility: visible; }
  100% { transform: translateY(-100%); visibility: hidden; }
`;

const StyledUserLocation = styled.div`
  & .selected-location {
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.background};
    cursor: ${(props) => props.usePointerForCursor && 'pointer'};
    border: none;
    text-align: right;
  }
  & .location-dropdown {
    position: fixed;
    top: -100%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 270px;
    will-change: transform;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 200ms;
    animation-name: ${slideUp};
    z-index: 2;
  }
  .location-dropdown.expand {
    top: 70px;
    animation-name: ${slideDown};
  }
  & .locations-search {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    background-color: #f5f5f5;
    border: none;
    height: 40px;
    padding: 10px 20px;
  }
  & .locations-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0px;
  }
  & .locations-list button {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.background};
    cursor: pointer;
    border: none;
    text-align: left;
    padding-left: 20px;
    height: 45px;
    width: 100%;
  }
  & .divider {
    background-color: ${(props) => props.theme.colors.background};
    text-align: left;
    padding: 15px 0 0 20px;
    color: ${(props) => props.theme.colors.primary};
    border-top: solid 1px ${(props) => props.theme.colors.primary};
  }
  & .count {
    font: ${(props) => props.theme.smallTextFont};
    background-color: ${(props) => props.theme.colors.background};
    padding: 10px;
    text-align: right;
  }
  & .separator {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary} !important;
    width: calc(100% - 25px);
    margin: -8px auto;
    z-index: 1000;
  }
  & .arrow {
    width: 8px;
    margin-left: 5px;
    margin-bottom: 1px;
  }

  & .under-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    z-index: -1;
    background-color: ${(props) => props.blurBackground && '#c1c1c1a6'};
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .selected-location {
      font: ${(props) => props.theme.smallTextFont};
      width: 100vw;
      text-align: center;
      margin-top: 43px;
    }

    & .location-dropdown {
      position: fixed;
      left: 0;
      width: 100%;
    }
  }
`;

UserLocation.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  updateLocation: PropTypes.func,
  selectedLocation: PropTypes.string,
  locationDropdownDisabled: PropTypes.bool.isRequired,
  lookupLocation: PropTypes.func,
  allStoreNamesAndNumbers: PropTypes.arrayOf(PropTypes.object),
};

UserLocation.defaultProps = {
  locations: [],
  updateLocation: () => {},
  lookupLocation: () => {},
  selectedLocation: '',
  allStoreNamesAndNumbers: [],
};

export default UserLocation;
