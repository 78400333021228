import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import ReactExport from 'react-export-excel';

import ooeConstants from '../../constants';
import { formatPhoneNumber } from '../../reducers/form';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ExportExcelEntireDays = (props) => {
  const {
    orders,
    dashboardOrders,
    allowExcelDownload,
    downloadExcel,
    excelExtractionError,
    specificDays,
  } = props;
  const dataset = orders?.map((order) => {
    const lineItems = order?.lineItems
      ?.map((item) => `${item.quantity} ${item.name}\n`)
      .join('');
    const subItems = order?.lineItems
      ?.map((item) => {
        let subItem = '';
        if (item.subItems) {
          subItem = item.subItems
            .map((s) => `${s.quantity} ${s.name}\n`)
            .join('');
        }
        if (item.comboItems) {
          subItem = item.comboItems
            .map((s) => `${s.quantity} ${s.name}\n`)
            .join('');
        }
        return subItem;
      })
      .join('');
    const specialInstructions =
      order.specialInstructions === 'No special instructions' ||
      order.specialInstructions === undefined
        ? ''
        : order.specialInstructions;

    let sc = '';
    if (order.secondaryContact) {
      sc = `${order.secondaryContact.firstName} ${
        order.secondaryContact.lastName
      } ${formatPhoneNumber(order.secondaryContact.phoneNumber)}`;
    }
    return {
      ...order,
      lineItems,
      subItems,
      specialInstructions,
      secondaryContact: sc,
    };
  });
  const buttonRef = useRef(null);
  const filename =
    specificDays?.startDate && specificDays?.endDate
      ? `${format(
          parse(
            specificDays?.startDate,
            ooeConstants.DATE_TIME_FORMAT.date,
            new Date(),
          ),
          'P',
        )}-${format(
          parse(
            specificDays?.endDate,
            ooeConstants.DATE_TIME_FORMAT.date,
            new Date(),
          ),
          'P',
        )}`
      : '';

  //this is made because download can be done only if we click on button but we have to separate geting data from api and downloading, if those conditions are good, with useRef
  //click will be simulated and downloadExcel reducer action will be run if not it will not be clicked an downloaded.
  useEffect(() => {
    if (
      orders.length === dashboardOrders.length &&
      orders.length > 0 &&
      allowExcelDownload &&
      !excelExtractionError
    ) {
      buttonRef.current.click();
      downloadExcel();
    }
  }, [orders, dashboardOrders, allowExcelDownload]);

  return (
    <ExcelFile
      className="excel-file"
      element={
        <button ref={buttonRef} style={{ display: 'none' }}>
          Button
        </button>
      }
      filename={filename}
      testingData={dataset}
    >
      <ExcelSheet className="excel-sheet" data={dataset} name="Orders">
        <ExcelColumn label="Date and Time" value="displayLongDate" />
        <ExcelColumn label="Destination" value="destination" />
        <ExcelColumn label="Items" value="lineItems" />
        <ExcelColumn label="Sub Items" value="subItems" />
        <ExcelColumn label="Special Instructions" value="specialInstructions" />
        <ExcelColumn label="Total Amount" value="total" />
        <ExcelColumn
          label="Primary Contact Name"
          value={(col) => `${col.givenName} ${col.familyName}`}
        />
        <ExcelColumn
          label="Primary Contact Phone Number"
          value={(col) => `${formatPhoneNumber(col.phoneNumber)}`}
        />
        <ExcelColumn label="Catering Reason" value="cateringReason" />
        <ExcelColumn label="Secondary POC" value="secondaryContact" />
      </ExcelSheet>
    </ExcelFile>
  );
};

ExportExcelEntireDays.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  dashboardOrders: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  allowExcelDownload: PropTypes.bool,
  downloadExcel: PropTypes.func,
  excelExtractionError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  specificDays: PropTypes.objectOf(PropTypes.string),
};

ExportExcelEntireDays.defaultProps = {
  orders: [],
  dashboardOrders: [],
  allowExcelDownload: false,
  downloadExcel: () => {},
  excelExtractionError: false,
  specificDays: {},
};

export default ExportExcelEntireDays;
