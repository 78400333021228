import { Badge } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../Icon';

function CartLink({ icon, cartItemCount, total, loading, dataCy }) {
  return (
    <StyledCartLink>
      <NavLink to={{ state: { modal: true } }}>
        <Badge badgeContent={cartItemCount} className="badge" data-cy={dataCy}>
          <Icon>{icon}</Icon>
          {loading ? (
            <div className="total loading">Calculating</div>
          ) : (
            <div className="total" data-cy="total">
              {total}
            </div>
          )}
        </Badge>
      </NavLink>
    </StyledCartLink>
  );
}

const StyledCartLink = styled.div`
  position: relative;
  min-width: 55px;
  border-radius: 2px;

  & a {
    text-decoration: none;
  }
  & svg {
    fill: ${(props) => props.theme.colors.primary};
  }
  & .badge {
    z-index: 200;
    display: unset;
  }
  & .badge > span {
    bottom: 32px;
    top: auto;
  }

  & .badge > div {
    top: 3px;
  }

  & .total {
    position: absolute;
    right: 10px;
    bottom: 3px;
    color: ${(props) => props.theme.colors.primary};
    font: ${(props) => props.theme.smallTextFont};
  }
  & .loading {
    right: -3px;
    color: ${(props) => props.theme.colors.disabled};
  }
`;

CartLink.propTypes = {
  icon: PropTypes.string.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  total: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
};

CartLink.defaultProps = {
  dataCy: '',
};

export default CartLink;
