/* eslint-disable react/forbid-prop-types */
import { Button } from '@cfa/react-components';
import { Box, Heading, Text } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';
import OverlayModal from './OverlayModal';

function AddressWarningModal({
  close,
  isOpen,
  error,
  availableLocations,
  updateUserLocation,
  lookupLocation,
}) {
  let icon = 'warning';
  let type;
  let heading;
  let message;
  let key;
  if (error) {
    ({ type, heading, message, key } = error);
    if (type) {
      icon = type.toLowerCase?.() ?? 'error';
    }
  }
  const changeLocationHandler = (e) => {
    lookupLocation(e.target.value);
    updateUserLocation(e.target.value);
  };
  return (
    <StyledAddressWarningModal close={close} isOpen={isOpen}>
      <Close onClick={close}>
        <Icon>close</Icon>
      </Close>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        m="3em"
      >
        <Icon>{icon}</Icon>
        <Heading as="h1" fontSize="5" m="1em">
          {heading}
        </Heading>
        <Text color="textColor" data-testid="message">
          {message}
          <br />
          {key !== 'OUT_OF_RANGE' && (
            <span>A valid address is required to submit this order.</span>
          )}
          <br />
          Please update the address.
        </Text>
        <Box>
          {availableLocations.length > 0 && (
            <select
              className="select-location"
              data-testid="select-location"
              defaultValue="DEFAULT"
              onChange={changeLocationHandler}
            >
              <option disabled value="DEFAULT">
                Select Available Location
              </option>
              {availableLocations.map((l) => (
                <option
                  data-testid="address-option"
                  key={l.locationNumber}
                  value={l.locationNumber}
                >
                  {`${l.name}, ${l.locationNumber}`}
                </option>
              ))}
            </select>
          )}
        </Box>
        <Button
          color="secondary"
          data-testid="button-ok"
          onClick={close}
          variant="filled"
        >
          Ok
        </Button>
      </Box>
    </StyledAddressWarningModal>
  );
}

export const StyledAddressWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
  .select-location {
    padding: 10px;
    border-color: #b1b1b1;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border: 1px solid #ccc;
      background: #fff;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      outline: none;
      font-size: 14px;
      font-family: ${(props) => props.theme.fontFamily};
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
`;

const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

AddressWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  error: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  availableLocations: PropTypes.arrayOf(PropTypes.object),
  updateUserLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
};

AddressWarningModal.defaultProps = {
  isOpen: false,
  error: {},
  availableLocations: [],
  updateUserLocation: () => {},
  lookupLocation: () => {},
};

export default AddressWarningModal;
