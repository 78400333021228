export const isRedirectToHttpsNecessary = (location) =>
  location.hostname !== 'localhost' && location.protocol !== 'https:';

const redirectToHttpsIfNecessary = ({ location, redirectToUrl }) => {
  if (isRedirectToHttpsNecessary(location)) {
    const httpsUrl = `https:${location.href.substring(location.protocol.length)}`;
    redirectToUrl(httpsUrl);
  }
};

export default redirectToHttpsIfNecessary;
