import { Section } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';

function EmptyCart({ className, message }) {
  return (
    <Section
      className={className}
      color="textColor"
      m="100px 50px"
      textAlign="center"
    >
      <Icon>bag</Icon>
      <div className="message">{message}</div>
    </Section>
  );
}

const StyledEmptyCart = styled(EmptyCart)`
  fill: ${(props) => props.theme.colors.text};

  & .message {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0;
  }
`;

EmptyCart.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default StyledEmptyCart;
