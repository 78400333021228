/* eslint-disable react/forbid-prop-types */
import { Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useState } from 'react';

import FeatureFlagService from '../../services/featureFlags';
import FavoriteOrder from '../FavoriteOrder/FavoriteOrder';
import PastOrdersCard from './PastOrdersCard';

const showReorderButton = () => FeatureFlagService.variation('reorder', false);

export const PastOrders = (props) => {
  const {
    addToFavorites,
    removeFromFavorites,
    updateFavoriteName,
    resetFavoriteActionErrors,
    pastAndFavoriteOrders,
    favoritesLoading,
    favoritesError,
    favoriteActionSuccessful,
    initiateEditOrder,
    customer,
    suggestedFavoriteName,
    currentFavoriteNames,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [favoriteOrderId, setFavoriteOrderId] = useState(null);
  const [favoriteName, setFavoriteName] = useState('');

  const handleAddToFavorites = (favName) => addToFavorites(orderId, favName);
  const handleRemoveFromFavorites = () => removeFromFavorites(favoriteOrderId);
  const handleUpdateFavoriteName = (favName) =>
    updateFavoriteName(favoriteOrderId, favName);

  const openModal = (id, favOrderId, name) => {
    setModalIsOpen(true);
    setOrderId(id);
    setFavoriteOrderId(favOrderId);
    setFavoriteName(name);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    resetFavoriteActionErrors();
  };

  if (pastAndFavoriteOrders.length === 0) {
    return null;
  }

  return (
    <Flex flexWrap="wrap" justifyContent={['center', 'end']} m="20px 0">
      {pastAndFavoriteOrders.map((order, idx) => (
        <PastOrdersCard
          /* eslint-disable-next-line */
          handleReorderClick={() => initiateEditOrder(order, customer, true)}
          key={`${order.id}-${idx}`}
          openFavoriteModal={openModal}
          order={order}
          showReorderButton={showReorderButton}
        />
      ))}
      {modalIsOpen && (
        <FavoriteOrder
          addToFavorites={handleAddToFavorites}
          close={closeModal}
          currentFavoriteNames={currentFavoriteNames}
          favoriteActionSuccessful={favoriteActionSuccessful}
          favoritesError={favoritesError}
          favoritesLoading={favoritesLoading}
          isFavorite={favoriteOrderId !== undefined}
          isOpen={modalIsOpen}
          removeFromFavorites={handleRemoveFromFavorites}
          setFavoriteName={favoriteName}
          suggestedFavoriteName={suggestedFavoriteName}
          updateFavoriteName={handleUpdateFavoriteName}
        />
      )}
    </Flex>
  );
};

PastOrders.propTypes = {
  pastAndFavoriteOrders: PropTypes.arrayOf(PropTypes.object),
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  updateFavoriteName: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  favoriteActionSuccessful: PropTypes.bool,
  resetFavoriteActionErrors: PropTypes.func,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  initiateEditOrder: PropTypes.func.isRequired,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
};

PastOrders.defaultProps = {
  pastAndFavoriteOrders: [],
  favoritesLoading: false,
  favoritesError: null,
  favoriteActionSuccessful: false,
  resetFavoriteActionErrors: () => {},
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
};

export default PastOrders;
