import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import ooe from '../../constants';
import { validateTaxId } from '../../util/validate';
import Radio from '../Radio/Radio';
import Defer from './Defer';
import NewPayment from './NewPayment';
import VaultedCards from './VaultedCards';

export function PaymentOptions(props) {
  const {
    vaultedCards,
    cardsLoading,
    paymentMethod,
    change,
    // taxExempt,
    validateZip,
    zipLoading,
    cardSelected,
    hideNewPaymentOption,
    validateZipSuccess,
    hidePayLater,
    allItemsPromoFree,
  } = props;
  let { selectedMethod } = paymentMethod;
  useEffect(() => {
    if (!cardsLoading && !selectedMethod) {
      selectedMethod = vaultedCards.length > 0 ? ooe.CREDIT : ooe.DEFER;
      change('selectedMethod', selectedMethod);
    } else if (
      !selectedMethod &&
      !allItemsPromoFree &&
      vaultedCards.length > 0
    ) {
      selectedMethod = ooe.CREDIT;
      change('selectedMethod', selectedMethod);
    } else if (allItemsPromoFree) {
      selectedMethod = ooe.DEFER;
      change('selectedMethod', selectedMethod);
    }
  }, [allItemsPromoFree, cardsLoading]);
  return (
    <StyledPaymentOptions>
      <Flex flexDirection="column">
        {vaultedCards.length > 0 && !allItemsPromoFree && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.CREDIT}
              component={Radio}
              id={ooe.CREDIT}
              label={ooe.CREDIT}
              name="selectedMethod"
            />
            {selectedMethod === ooe.CREDIT && (
              <VaultedCards
                cardSelected={cardSelected}
                cardsLoading={cardsLoading}
                data-testid="vaultedCards"
                validateZip={validateZip}
                validateZipSuccess={validateZipSuccess}
                vaultedCards={vaultedCards}
                zipLoading={zipLoading}
              />
            )}
          </Box>
        )}
        {!hideNewPaymentOption && !allItemsPromoFree && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.REQUEST_PAYMENT}
              className="new-payment"
              component={Radio}
              id={ooe.REQUEST_PAYMENT}
              label={ooe.REQUEST_PAYMENT}
              name="selectedMethod"
            />
            {selectedMethod === ooe.REQUEST_PAYMENT && <NewPayment />}
          </Box>
        )}
        {(!hidePayLater || allItemsPromoFree) && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.DEFER}
              className="pay-later"
              component={Radio}
              id={ooe.DEFER}
              label={ooe.DEFER}
              name="selectedMethod"
            />
            {selectedMethod === ooe.DEFER && <Defer className="defer" />}
          </Box>
        )}
        {/* HIDE TAX EXEMPT TOGGLE FOR NOW
        <Column xs="12">
          <Field name="taxExempt" component={Toggle} label="Tax Exempt?" />
          { taxExempt &&
          <Field
            name="taxId"
            component={ValidatedField}
            type="text"
            placeholder="Tax Id Number (xx-xxxxxxx)"
            maxLength="10"
          /> }
        </Column>
        */}
      </Flex>
    </StyledPaymentOptions>
  );
}

const StyledPaymentOptions = styled.div`
  clear: both;
  & label {
    display: flex;
    width: 100%;
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }
  & .radio-option {
    margin: 5px;
    float: left;
    text-align: center;
    width: 100%;
  }
`;

PaymentOptions.propTypes = {
  vaultedCards: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  cardsLoading: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  change: PropTypes.func.isRequired,
  // taxExempt: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool.isRequired,
  cardSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  hideNewPaymentOption: PropTypes.bool,
  validateZipSuccess: PropTypes.func,
  hidePayLater: PropTypes.bool,
  allItemsPromoFree: PropTypes.bool,
};

PaymentOptions.defaultProps = {
  vaultedCards: null,
  // taxExempt: false,
  hideNewPaymentOption: false,
  validateZipSuccess: () => {},
  hidePayLater: false,
  allItemsPromoFree: false,
};

export default reduxForm({
  form: ooe.GET_FORM_TYPES.PAYMENT_METHOD,
  destroyOnUnmount: false,
  validate: validateTaxId,
  initialValues: { paymentMethod: '' },
})(PaymentOptions);
