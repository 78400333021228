import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

function NewPayment() {
  return (
    <div>
      <StyledNewPayment>
        Guest will receive an email with payment instructions and will have 4
        hours to input payment. Please note order will not go to deferred order
        queue until payment has been received. Guest will also be prompted to
        create a Chick-fil-A One account and vault payment for future use.
        <NavLink className="link" to={{ state: { cardModal: true } }}>
          Still confused?
        </NavLink>
      </StyledNewPayment>
    </div>
  );
}

const StyledNewPayment = styled.div`
  text-align: left;
  padding: 5px 41px;
  color: ${(props) => props.theme.colors.text};
  line-height: 1.4em;
  clear: both;

  & .link {
    display: block;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default NewPayment;
