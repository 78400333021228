/* eslint-disable react/forbid-prop-types */
import { ChickenSpinner } from '@cfacorp/cowponents';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styled from 'styled-components';

import constants from '../../constants';
import history from '../../util/history';
import DashboardActions from '../DashboardActions/DashboardActions';
import DashboardError from '../ErrorMessage/DashboardError';
import EventSummary from '../EventSummary/EventSummary';
import Icon from '../Icon';
import OverlayModal from '../Modal/OverlayModal';
import OrderHistory from '../OrderHistory/OrderHistory';
import OrderSummary from '../OrderSummary/OrderSummary';
import Totals from '../Totals/Totals';
import DetailViewHeader from './DetailViewHeader';

function DetailView(props) {
  useEffect(() => {
    const { lookupOrderDetails, lookupCustomerDetails, baseOrder } = props;
    const { id, cfaId } = baseOrder;
    lookupOrderDetails(id, cfaId);
    if (cfaId) {
      lookupCustomerDetails(id, cfaId);
    }
  }, []);

  const {
    className,
    lookupDetailsLoading,
    orderDetails,
    restaurantContactDetails,
    cancelOrder,
    dashboardActionLoading,
    tooLateToCancelOrEdit,
    resendPaymentEmail,
    paymentEmailResent,
    orderHistory,
    isPOSOrder,
    isErrorStatus,
    initiateEditOrder,
  } = props;
  const {
    promiseDateTime,
    time,
    lineItems,
    deliveryAddress,
    companyName,
    email,
    phone,
    guestCount,
    paperGoods,
    givenName,
    familyName,
    destination,
    specialInstructions,
    displaySubtotal,
    displayPrice,
    cfaId,
    transId,
    deliveryTip,
    cateringReason,
    secondaryContact,
    displayTaxAmount,
  } = orderDetails;
  const eventDetails = {
    guestCount,
    paperGoods,
    specialInstructions,
    time,
    date: promiseDateTime ? parseISO(promiseDateTime) : {},
  };
  const deliveryAddressWithCompany = {
    ...deliveryAddress,
    companyName,
  };
  const guestDetails = {
    email,
    phone,
    cfaId,
    first: givenName,
    last: familyName,
  };
  const close = () => history.push('/more/dashboard');
  const deliveryTipIsDisplayed =
    deliveryTip && Object.keys(deliveryTip).length > 0;

  return (
    <OverlayModal
      className={className}
      close={close}
      data-cy="overlay-modal"
      isOpen
      title="Dashboard Detail View"
    >
      {lookupDetailsLoading ? (
        <ChickenSpinner className="detail-spinner" title="CFA Spinner" />
      ) : (
        <>
          <div className="mainContent">
            <DetailViewHeader
              close={close}
              dashboardActionLoading={dashboardActionLoading}
              orderDetails={orderDetails}
            />
            <EventSummary
              addressValidated
              cateringReason={cateringReason}
              className="event-summary"
              deliveryAddress={
                isPOSOrder ? deliveryAddress : deliveryAddressWithCompany
              }
              destination={destination}
              eventDetails={eventDetails}
              eventSummaryValid
              guestDetails={guestDetails}
              isErrorStatus={isErrorStatus}
              isPOSOrder={isPOSOrder}
              restaurantContactDetails={restaurantContactDetails}
              secondaryContactDetails={secondaryContact}
              transId={transId}
            />
            <h3>Order Summary</h3>
            <OrderSummary cartItems={lineItems} />
            <Totals
              deliveryTip={deliveryTip}
              deliveryTipIsDisplayed={deliveryTipIsDisplayed}
              subTotal={displaySubtotal}
              taxAmount={displayTaxAmount}
              taxAndTotal={displayPrice}
            />
            {!isPOSOrder && <OrderHistory orderHistory={orderHistory} />}
          </div>
          <div className="errors-warnings-box">
            <DashboardError {...props} />
            {isPOSOrder && (
              <PosOrderWarningBox>
                <Icon className="icn">warning</Icon>
                <div>{constants.POS_ORDER_WARNING_TEXT}</div>
              </PosOrderWarningBox>
            )}
          </div>
          <DashboardActions
            cancelOrder={cancelOrder}
            dashboardActionLoading={dashboardActionLoading}
            guestDetails={guestDetails}
            initiateEditOrder={initiateEditOrder}
            isPOSOrder={isPOSOrder}
            orderDetails={orderDetails}
            paymentEmailResent={paymentEmailResent}
            resendPaymentEmail={resendPaymentEmail}
            restaurantContactDetails={restaurantContactDetails}
            tooLateToCancelOrEdit={tooLateToCancelOrEdit}
          />
        </>
      )}
    </OverlayModal>
  );
}

const PosOrderWarningBox = styled.div`
  font: ${(props) => props.theme.smallBoldFont};
  margin: 10px;
  padding: 0 7px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.warning};
  border: 1px solid ${(props) => props.theme.colors.warning};
  background-color: #fffcee;
  & .icn {
    min-width: 17px;
  }
  & svg {
    width: 17px;
    margin: 0 5px 0 0;
  }
`;

const StyledDetailView = styled(DetailView)`
  min-width: 65%;
  & .mainContent {
    padding: 20px;
  }

  & .event-summary {
    margin: 20px 0 40px;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .detail-spinner {
      display: flex;
      height: 70vh;
    }
    & .errors-warnings-box {
      margin-bottom: 130px;
    }
  }
`;

DetailView.propTypes = {
  className: PropTypes.string.isRequired,
  orderDetails: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  isErrorStatus: PropTypes.bool,
  cancelOrder: PropTypes.func.isRequired,
  baseOrder: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ).isRequired,
  lookupOrderDetails: PropTypes.func.isRequired,
  lookupCustomerDetails: PropTypes.func.isRequired,
  lookupDetailsLoading: PropTypes.bool.isRequired,
  dashboardActionLoading: PropTypes.bool.isRequired,
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  detailViewErrors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ),
  bypassBusinessRules: PropTypes.bool,
  tooLateToCancelOrEdit: PropTypes.bool,
  resendPaymentEmail: PropTypes.func.isRequired,
  paymentEmailResent: PropTypes.bool,
  orderHistory: PropTypes.arrayOf(PropTypes.object),
  isPOSOrder: PropTypes.bool,
  initiateEditOrder: PropTypes.func,
};

DetailView.defaultProps = {
  orderDetails: {},
  isErrorStatus: false,
  restaurantContactDetails: {},
  detailViewErrors: [],
  bypassBusinessRules: false,
  tooLateToCancelOrEdit: false,
  paymentEmailResent: false,
  orderHistory: [],
  isPOSOrder: false,
  initiateEditOrder: () => {},
};

export default StyledDetailView;
