import {
  assoc,
  clone,
  contains,
  equals,
  indexBy,
  is,
  length,
  map,
  prop,
  propOr,
  reduce,
} from 'ramda';
import { v4 } from 'uuid';

import ooeConstants from '../constants';

const mapModifierToCartModifier = ({ itemTag, quantity = 1, comboTag }) => ({
  quantity,
  comboTag,
  tag: itemTag,
});

const indexByTag = indexBy(prop('tag'));

function findSide(lineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    return comboItems.find((item) =>
      contains(item.itemTag, ooeConstants.SIDES),
    );
  }
  return {};
}

function findDessert(lineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    return comboItems.find((item) =>
      contains(item.itemTag, ooeConstants.DESSERTS),
    );
  }
  return {};
}

function getComboModifiers(lineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    const comboModifiers = reduce(
      (acc, value) => {
        const { modifiers, itemTag } = value;
        if (is(Array, modifiers) && length(modifiers) > 0) {
          const taggedModifiers = map(assoc('comboTag', itemTag), modifiers);
          return [...acc, ...taggedModifiers];
        }
        return acc;
      },
      [],
      comboItems,
    );

    return comboModifiers;
  }
  return [];
}

function getAllModifiers(lineItem) {
  const itemModifiers = propOr([], 'modifiers', lineItem);
  const comboModifiers = getComboModifiers(lineItem);
  const allModifiers = [...itemModifiers, ...comboModifiers];
  return map(mapModifierToCartModifier, allModifiers);
}

function getIndexedModifiers(lineItem) {
  const allModifiers = getAllModifiers(lineItem);
  return indexByTag(allModifiers);
}

export function mapLineItemToCartItem(
  lineItem = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  indexed = false,
  indexedModifiers = false,
) {
  const { itemTag, tag, promoFree } = lineItem;
  const baseCartItem = {
    tag: itemTag || tag,
    quantity: propOr(1, 'quantity', lineItem),
    specialInstructions: propOr('', 'specialInstructions', lineItem),
    promoFree,
  };

  const modifiers = indexedModifiers
    ? getIndexedModifiers(lineItem)
    : getAllModifiers(lineItem);
  const selectedSideTag = prop('itemTag', findSide(lineItem));
  const selectedDessertTag = prop('itemTag', findDessert(lineItem));
  return {
    ...baseCartItem,
    selectedSideTag,
    selectedDessertTag,
    modifiers,
    id: v4(),
  };
}

export const convertLineItemsToCartItems = (
  lineItems = [],
  indexed = false,
  indexedModifiers = false,
) => {
  const mappedLineItems = map(
    (i) => mapLineItemToCartItem(i, indexed, indexedModifiers),
    lineItems,
  );
  if (indexed) {
    return indexByTag(mappedLineItems);
  }
  return mappedLineItems;
};

export const consolidateItems = (items) => {
  const src = clone(items);
  const dest = [];

  if (items) {
    src.forEach((srcItem) => {
      let updatedQty = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const destItem of dest) {
        if (
          equals(
            {
              ...destItem,
              id: 0,
              quantity: 1,
              key: 1,
              price: 1,
            },
            {
              ...srcItem,
              id: 0,
              quantity: 1,
              key: 1,
              price: 1,
            },
          )
        ) {
          destItem.quantity += srcItem.quantity;
          updatedQty = true;
          break;
        }
      }
      if (!updatedQty) {
        dest.push(srcItem);
      }
    });
  }
  return dest;
};
