import { ChickenSpinner } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function InitialLoad({ className }) {
  return (
    <div className={className}>
      <h3>Catering Management Tool</h3>
      <ChickenSpinner data-testid="cfa-spinner" />
      {/* <p>&quot;This is the best Catering tool I&#39;ve ever used&quot;. -Everyone</p> */}
    </div>
  );
}

InitialLoad.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledInitialLoad = styled(InitialLoad)`
  font-family: ${(props) => props.theme.fontFamily};
  text-align: center;
  margin-top: 20vh;

  & .initial-load {
    width: 100px;
    margin: 20px auto;
  }

  & p {
    font-style: italic;
    font-size: 14px;
    margin: 10px;
  }
`;

export default StyledInitialLoad;
