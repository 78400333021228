import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';

import { formatPhoneNumber } from '../../reducers/form';
import DashboardActionButton from '../DashboardActions/DashboardActionButton';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const OrderExportExcel = (props) => {
  const { orderDetails, restaurantContactDetails } = props;
  const { lineItems } = orderDetails;
  const { customerName } = orderDetails;
  let sc = '';
  if (orderDetails.secondaryContact) {
    sc = `${orderDetails.secondaryContact.firstName} ${orderDetails.secondaryContact.lastName} ${formatPhoneNumber(orderDetails.secondaryContact.phoneNumber)}`;
  }
  const dataSet1 = [{ ...orderDetails, secondaryContact: sc }];
  const dataSet2 = lineItems?.map((i) => {
    let mod = '';
    let combo = '';
    if (i.modifiers) {
      mod = i.modifiers
        .map((m) => `${m.name} ${m.quantity} $${m.price}`)
        .join('; ');
    }
    if (i.comboItems) {
      combo = i.comboItems
        .map((c) => `${c.name} $${c.priceAdjustment}`)
        .join('; ');
    }
    return {
      ...i,
      modifiers: mod,
      comboItems: combo,
    };
  });
  return (
    <ExcelFile
      element={
        <DashboardActionButton
          buttonText="Extract to Excel"
          className="download-excel"
          icon="download-excel"
        />
      }
      filename={customerName}
      testingData1={dataSet1}
      testingData2={dataSet2}
    >
      <ExcelSheet data={dataSet1} name="Order Details">
        <ExcelColumn label="Date and Time" value="displayLongDate" />
        <ExcelColumn label="Primary Contact Name" value="customerName" />
        <ExcelColumn label="CFA Loyalty" value="cfaLoyalty" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Primary Contact Phone" value="phone" />
        <ExcelColumn label="Location Number" value="locationNumber" />
        <ExcelColumn label="Destination" value="destination" />
        {orderDetails.destination === 'Delivery' ? (
          <ExcelColumn
            label="Delivery Address"
            value={(col) =>
              `${col.deliveryAddress.addressLine1} ${col.deliveryAddress.addressLine2 || ''} ${col.deliveryAddress.addressLine3 || ''} ${col.deliveryAddress.city} ${col.deliveryAddress.county} ${col.deliveryAddress.state} ${col.deliveryAddress.zip} ${col.deliveryAddress.zipExtension || ''} `
            }
          />
        ) : (
          <ExcelColumn
            label="Pickup Restaurant"
            value={() =>
              `${restaurantContactDetails.restaurantName} ${restaurantContactDetails.restaurantAddress1} ${restaurantContactDetails.restaurantAddress2} phone:${restaurantContactDetails.restaurantPhone}`
            }
          />
        )}
        <ExcelColumn label="Client" value="displayClient" />
        <ExcelColumn label="Date and Time" value="displayLongDate" />
        <ExcelColumn label="Subtotal Price" value="displaySubtotal" />
        <ExcelColumn label="Total Price" value="displayPrice" />
        <ExcelColumn label="TransactionID" value="transId" />
        <ExcelColumn
          label="Status"
          value={(col) => col.displayStatus.statusName}
        />
        <ExcelColumn label="Special Instructions" value="specialInstructions" />
        <ExcelColumn label="Phone" value="phone" />
        <ExcelColumn label="Catering Reason" value="cateringReason" />
        <ExcelColumn label="Secondary POC" value="secondaryContact" />
      </ExcelSheet>
      <ExcelSheet data={dataSet2} name="Order Summary">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Combo Items" value="comboItems" />
        <ExcelColumn label="Quantity" value="quantity" />
        <ExcelColumn label="Retail Price" value="retailPrice" />
        <ExcelColumn label="Price" value="price" />
        <ExcelColumn label="Modifiers" value="modifiers" />
        <ExcelColumn label="Special Instruction" value="specialInstructions" />
      </ExcelSheet>
    </ExcelFile>
  );
};

OrderExportExcel.propTypes = {
  orderDetails: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
};

OrderExportExcel.defaultProps = {
  orderDetails: {},
  restaurantContactDetails: {},
};

export default OrderExportExcel;
