import { Button } from '@cfa/react-components';
import PropTypes from 'prop-types';

const CreateInvoiceButton = ({ onPrint, children }) => (
  <Button color="secondary" fullWidth onClick={onPrint} variant="outlined">
    {children}
  </Button>
);

CreateInvoiceButton.propTypes = {
  onPrint: PropTypes.func,
  children: PropTypes.string,
};

CreateInvoiceButton.defaultProps = {
  onPrint: () => {},
  children: '',
};

export default CreateInvoiceButton;
