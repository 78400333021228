import PropTypes from 'prop-types';
import styled from 'styled-components';

function Tabs({ children }) {
  return <StyledTabs>{children}</StyledTabs>;
}

const StyledTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Tabs;
