import { Box, Text, Toggle as CowponentsToggle } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const PaperGoodsToggle = ({
  id,
  label,
  isToggledPaperGoods,
  onTogglePaperGoods,
  onSetPaperGoodsOptions,
}) => {
  useEffect(() => {
    onSetPaperGoodsOptions(id, isToggledPaperGoods);
  }, []);
  const onChangeHandler = (event) => {
    onTogglePaperGoods(id);
    onSetPaperGoodsOptions(id, event.target.checked);
  };
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Text style={{ fontSize: '20px' }}>{label}</Text>
      <CowponentsToggle
        checked={isToggledPaperGoods}
        id={id}
        onChange={onChangeHandler}
      />
    </Box>
  );
};

PaperGoodsToggle.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isToggledPaperGoods: PropTypes.bool,
  onTogglePaperGoods: PropTypes.func,
  onSetPaperGoodsOptions: PropTypes.func,
};

PaperGoodsToggle.defaultProps = {
  id: '',
  label: '',
  isToggledPaperGoods: false,
  onTogglePaperGoods: () => {},
  onSetPaperGoodsOptions: () => {},
};

export default PaperGoodsToggle;
