import { from, throwError } from 'rxjs';

import { leaveBreadcrumb } from '../services/bugsnag';
import { userErrorMessages } from './customerErrorMessages';
import handleErrors, { handleFetchFail } from './handleErrors';

export class ApiCall {
  constructor(props) {
    const { method, url, auth, form, maxRetries, mapper, bugsnag } = props;
    let { body } = props;
    this.headers = {};
    if (!form) {
      this.headers.Accept = 'application/json';
      this.headers['Content-Type'] = 'application/json';
      try {
        body = JSON.stringify(body);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e, body);
      }
    }
    this.auth = auth || {};
    if (auth) {
      this.headers.Authorization = `${this.auth.type} ${this.auth.apiKey}`;
    }
    this.method = method || 'GET';
    this.url = url;
    this.body =
      method === 'PUT' || method === 'POST' || method === 'PATCH' ? body : null;
    this.numRetries = 0;
    // eslint-disable-next-line
    this.maxRetries = maxRetries === 0 ? 0 : maxRetries || 5;
    this.mapper = mapper;
    this.appendNewToken = this.appendNewToken.bind(this);
    this.execute = this.execute.bind(this);
    this.retry = this.retry.bind(this);
    this.bugsnag = bugsnag;
  }

  fetchCall() {
    const fetchOptions = {
      method: this.method,
      headers: this.headers,
      body: this.body,
    };
    return fetch(this.url, fetchOptions);
  }

  appendNewToken(tokens) {
    if (this.auth.type === 'Bearer') {
      this.auth.apiKey = tokens.apiKey;
    } else if (this.auth.type === 'JWTBearer') {
      this.auth.apiKey = tokens.accessToken;
    }
    this.headers.Authorization = `${this.auth.type} ${this.auth.apiKey}`;
  }

  execute() {
    leaveBreadcrumb(
      this.bugsnag?.breadcrumb || this.bugsnag?.context || 'ApiCall Request',
      {
        url: this.url,
        method: this.method,
        body: this.body,
        context: this.bugsnag?.context,
        info: this.bugsnag?.info,
      },
    );

    if (this.auth.type && !this.auth.apiKey) {
      // can happen if local storage were to get corrupted
      // clear local storage and do clean login / refresh
      // otherwise eternal spinner is the result and user would have to manually clear
      localStorage.removeItem('cfa_token_key');
      localStorage.removeItem('okta_token_key');
      window.location.assign(window.location.origin);
      return throwError(userErrorMessages.NO_TOKEN);
    }
    if (this.mapper) {
      return from(
        this.fetchCall()
          .catch(handleFetchFail)
          .then((response) => handleErrors(response, this.bugsnag))
          .then(this.mapper),
      );
    }
    return from(
      this.fetchCall()
        .catch(handleFetchFail)
        .then((response) => handleErrors(response, this.bugsnag)),
    );
  }

  retry(tokens) {
    this.appendNewToken(tokens);
    this.numRetries += 1;
    return this.execute();
  }
}

function request(props) {
  return new ApiCall(props);
}

export default request;
