import { Box, Heading, Text } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ooeConstants from '../../constants';
import Icon from '../Icon';
import OverlayModal from './OverlayModal';

const EmptyCfaPhoneFieldWarningModal = ({ isOpen, onClose }) => (
  <StyledEmptyCfaPhoneFiledWarningModal close={onClose} isOpen={isOpen}>
    <Close className="close" onClick={onClose}>
      <Icon>close</Icon>
    </Close>
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="3em"
    >
      <Icon>warning</Icon>
      <Heading as="h1" fontSize="5" m="1em">
        {ooeConstants.EMPTY_PHONE_FIELD_WARNING_HEADING}
      </Heading>
      <Text color="textColor">
        {ooeConstants.EMPTY_PHONE_FIELD_WARNING_MESSAGE}
      </Text>
    </Box>
  </StyledEmptyCfaPhoneFiledWarningModal>
);

export const StyledEmptyCfaPhoneFiledWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
  @media (max-width: ${(props) => props.theme.phone}) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border: 1px solid #ccc;
      background: #fff;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      outline: none;
      font-size: 14px;
      font-family: ${(props) => props.theme.fontFamily};
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
`;

export const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

EmptyCfaPhoneFieldWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

EmptyCfaPhoneFieldWarningModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default EmptyCfaPhoneFieldWarningModal;
