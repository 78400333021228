import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import styled from 'styled-components';

import { notifyBugsnag } from '../../services/bugsnag';
import Disclaimer from '../Disclaimer/Disclaimer';
import Icon from '../Icon';

function ValidationMessage(props) {
  const {
    deliveryAddress,
    validateError,
    validateAddressIsLoading,
    validateDeliveryRangeIsLoading,
  } = props;
  const {
    streetNumber,
    route,
    locality,
    administrativeAreaLevelOne,
    postalCode,
  } = deliveryAddress;
  let icon;
  let message;

  if (
    (validateAddressIsLoading || validateDeliveryRangeIsLoading) &&
    streetNumber !== '' &&
    route !== '' &&
    locality !== '' &&
    administrativeAreaLevelOne !== ''
  ) {
    icon = 'loading';
    message = 'Validating address...';
  } else if (
    streetNumber === '' ||
    route === '' ||
    locality === '' ||
    administrativeAreaLevelOne === '' ||
    postalCode === ''
  ) {
    icon = 'warning';
    message = 'Please complete all fields noted as required to process order';
  } else if (
    isNil(validateError) &&
    streetNumber !== '' &&
    route !== '' &&
    locality !== '' &&
    administrativeAreaLevelOne !== '' &&
    postalCode !== ''
  ) {
    icon = 'success';
    message = 'Delivery location accepted';
  } else if (!isNil(validateError)) {
    try {
      icon = validateError.type.toLowerCase();
      message = validateError.message;
    } catch (error) {
      // we have seen the odd case of bad validateError data coming in, we catch here so that
      // we can notify bugsnag for better diagnostics but in the meantime at least sanitize
      // the icon and message so we can continue on
      icon = validateError.type?.toLowerCase?.() ?? 'error';
      message = validateError.message ?? 'Unknown validation message error';
      notifyBugsnag(
        'ValidationMessage encountered unexpected validateError data',
        {
          context: 'Validation Message',
          info: {
            validateError,
            error,
            returnedValues: {
              icon,
              message,
            },
          },
        },
      );
    }
  }

  return (
    <StyledValidationMessage>
      {icon !== undefined && <Icon>{icon}</Icon>}
      <div className="message">{message}</div>
    </StyledValidationMessage>
  );
}

const StyledValidationMessage = styled(Disclaimer)`
  height: 19px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  & svg {
    width: 17px;
    margin: 0 0 2px 0;
  }
  & .message {
    margin-left: 5px;
  }
`;

ValidationMessage.propTypes = {
  deliveryAddress: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  validateError: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  validateAddressIsLoading: PropTypes.bool,
  validateDeliveryRangeIsLoading: PropTypes.bool,
};

ValidationMessage.defaultProps = {
  deliveryAddress: {},
  validateError: null,
  validateAddressIsLoading: false,
  validateDeliveryRangeIsLoading: false,
};

export default ValidationMessage;
