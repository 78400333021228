import { Button } from '@cfa/react-components';
import { Section, SectionHeader } from '@cfacorp/cowponents';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import ooeConstants from '../../constants';
import { notifyBugsnag } from '../../services/bugsnag';
import theme from '../../theme';
import Footer from '../Footer/Footer';
import Logo from '../Logo/Logo';
import Navigation from '../Navigation/Navigation';

const reloadApp = () => {
  window.location.assign(window.location.origin);
};

const UnrecoverableError = () => {
  useEffect(() => {
    notifyBugsnag('Unrecoverable error view displayed', {
      context: 'Unrecoverable Error',
      message:
        'This exists as a way to analyze and diagnose the path that led a user to the Uncoverable Error screen',
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Navigation leftDesktop={<Logo />} leftMobile={<Logo />}>
          <></>
        </Navigation>
        <Section style={{ minHeight: 200 }}>
          <SectionHeader>Oops!</SectionHeader>
          <p>
            There was an unrecoverable error. Please try again or contact
            support.
          </p>
          <Button color="secondary" onClick={reloadApp}>
            Try Again
          </Button>
        </Section>
        <Footer>{`Version ${ooeConstants.APP_VERSION}`}</Footer>
      </>
    </ThemeProvider>
  );
};

export default UnrecoverableError;
