import {
  Section,
  SectionHeader,
  SegmentButton,
  SegmentGroup,
} from '@cfacorp/cowponents';
import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { connect } from 'react-redux';

import DestinationForm from '../components/Forms/DestinationForm';
import DetailsForm from '../components/Forms/DetailsForm';
import SecondaryContactField from '../components/Forms/SecondaryContactField';
import ooe from '../constants';
import {
  selectHoursOfOperation,
  selectLeadTimeWarning,
  selectSpecialEvents,
  selectSpecialInstructionMessage,
} from '../reducers';
import {
  actions as formActions,
  selectCateringOccasion,
  selectDate,
  selectDateIsToday,
  selectSecondaryContactValues,
  selectTimeShouldClear,
} from '../reducers/form';
import {
  actions as guestActions,
  selectNoPastDeliveryAddresses,
  selectPastDeliveryAddresses,
  selectPastDeliveryAddressesLoading,
  selectPastOrders,
} from '../reducers/guest';
import {
  actions as orderActions,
  selectAvailableDates,
  selectAvailableLocations,
  selectDatesLoading,
  selectDeliveryAddress,
  selectDeliveryAddressValid,
  selectDeliveryError,
  selectDestination,
  selectShowWarningModal,
  selectValidateAddressLoading,
  selectValidateDeliveryRangeLoading,
} from '../reducers/order';
import {
  actions as userActions,
  selectBypassBusinessRules,
  selectDeliveryRange,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationTimezone,
} from '../reducers/user';

const destinations = ooe.CATERING_METHODS;

export const EventDetails = (props) => {
  const {
    destination,
    availableDates,
    selectedDate,
    updateUserAddress,
    deliveryAddress,
    dateChanged,
    autocompleteAddress,
    validateError,
    deliveryAddressValid,
    validateAddressIsLoading,
    availableDatesLoading,
    timeChanged,
    guestCountChanged,
    specialInstructionMessage,
    bypassBusinessRules,
    dateIsToday,
    timeShouldClear,
    resetTimeFormValues,
    timeZone,
    leadTimeWarning,
    showWarningModal,
    closeWarningModal,
    deliveryRange,
    hoursOfOperation,
    specialEvents,
    validateDeliveryRangeIsLoading,
    availableLocations,
    updateUserLocation,
    lookupLocation,
    getPastDeliveryAddresses,
    pastOrders,
    pastDeliveryAddresses,
    pastDeliveryAddressesLoading,
    noPastDeliveryAddresses,
    changeDestination,
    cateringReason,
    clearUserAddressFields,
    deliveryAccepted,
    pickupAccepted,
    addSecondaryContact,
    secondaryContactValues,
  } = props;

  const { PICKUP, DELIVERY } = ooe;
  const secondaryContactTitle = `Secondary ${destination === DELIVERY ? 'Delivery' : 'Pickup'} POC Information`;

  const setDestination = destination === DELIVERY ? DELIVERY : PICKUP;

  const isDisabled = (currentDestination) =>
    (currentDestination === DELIVERY && !deliveryAccepted) ||
    (currentDestination === PICKUP && !pickupAccepted);

  const isSelected = (currentDestination) => currentDestination === destination;

  return (
    <>
      <Section>
        <SegmentGroup
          data-testid="segment-group"
          defaultValue={setDestination}
          onChange={(event) => changeDestination(event.target.value)}
        >
          {destinations.map((dest) => (
            <SegmentButton
              data-testid="dest-segment-button"
              disabled={isDisabled(dest)}
              key={dest}
              selected={isSelected(dest)}
              value={dest}
            >
              {dest}
            </SegmentButton>
          ))}
        </SegmentGroup>
        <SectionHeader>Event Details</SectionHeader>
        <DetailsForm
          availableDates={availableDates}
          availableDatesLoading={availableDatesLoading}
          bypassBusinessRules={bypassBusinessRules}
          cateringReason={cateringReason}
          dateChanged={dateChanged}
          dateIsToday={dateIsToday}
          deliveryAddress={deliveryAddress}
          guestCountChanged={guestCountChanged}
          hoursOfOperation={hoursOfOperation}
          leadTimeWarning={leadTimeWarning}
          resetTimeFormValues={resetTimeFormValues}
          selectedDate={selectedDate}
          specialEvents={specialEvents}
          specialInstructionMessage={specialInstructionMessage}
          timeChanged={timeChanged}
          timeShouldClear={timeShouldClear}
          timeZone={timeZone}
          updateUserAddress={updateUserAddress}
        />
      </Section>
      {destination === DELIVERY && (
        <>
          <Section>
            <SectionHeader>Delivery Location</SectionHeader>
            <DestinationForm
              autocompleteAddress={autocompleteAddress}
              availableLocations={availableLocations}
              clearUserAddressFields={clearUserAddressFields}
              closeWarningModal={closeWarningModal}
              deliveryAddress={deliveryAddress}
              deliveryAddressValid={deliveryAddressValid}
              deliveryRange={deliveryRange}
              getPastDeliveryAddresses={getPastDeliveryAddresses}
              lookupLocation={lookupLocation}
              noPastDeliveryAddresses={noPastDeliveryAddresses}
              pastDeliveryAddresses={pastDeliveryAddresses}
              pastDeliveryAddressesLoading={pastDeliveryAddressesLoading}
              pastOrders={pastOrders}
              showWarningModal={showWarningModal}
              updateUserAddress={updateUserAddress}
              updateUserLocation={updateUserLocation}
              validateAddressIsLoading={validateAddressIsLoading}
              validateDeliveryRangeIsLoading={validateDeliveryRangeIsLoading}
              validateError={validateError}
            />
          </Section>
        </>
      )}
      <Section>
        <SectionHeader>
          {secondaryContactTitle}
          <small> (Optional)</small>
        </SectionHeader>
        <SecondaryContactField
          addSecondaryContact={addSecondaryContact}
          secondaryContactValues={secondaryContactValues}
        />
      </Section>
    </>
  );
};

EventDetails.propTypes = {
  destination: PropTypes.string,
  availableDates: PropTypes.arrayOf(PropTypes.any),
  availableDatesLoading: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateUserAddress: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  dateChanged: PropTypes.func.isRequired,
  timeChanged: PropTypes.func.isRequired,
  autocompleteAddress: PropTypes.func,
  validateError: PropTypes.objectOf(PropTypes.string),
  deliveryAddressValid: PropTypes.bool,
  validateAddressIsLoading: PropTypes.bool,
  validateDeliveryRangeIsLoading: PropTypes.bool,
  guestCountChanged: PropTypes.func.isRequired,
  specialInstructionMessage: PropTypes.string,
  bypassBusinessRules: PropTypes.bool,
  dateIsToday: PropTypes.bool,
  timeShouldClear: PropTypes.bool,
  resetTimeFormValues: PropTypes.func,
  timeZone: PropTypes.string,
  leadTimeWarning: PropTypes.objectOf(PropTypes.string),
  showWarningModal: PropTypes.bool,
  closeWarningModal: PropTypes.func,
  deliveryRange: PropTypes.number,
  hoursOfOperation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  specialEvents: PropTypes.array,
  availableLocations: PropTypes.arrayOf(PropTypes.any),
  updateUserLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
  getPastDeliveryAddresses: PropTypes.func,
  pastOrders: PropTypes.arrayOf(PropTypes.any),
  pastDeliveryAddresses: PropTypes.arrayOf(PropTypes.any),
  pastDeliveryAddressesLoading: PropTypes.bool,
  noPastDeliveryAddresses: PropTypes.bool,
  changeDestination: PropTypes.func,
  cateringReason: PropTypes.string,
  clearUserAddressFields: PropTypes.func,
  deliveryAccepted: PropTypes.bool,
  pickupAccepted: PropTypes.bool,
  addSecondaryContact: PropTypes.func,
  secondaryContactValues: PropTypes.objectOf(PropTypes.string),
};

EventDetails.defaultProps = {
  destination: '',
  availableDates: [],
  availableDatesLoading: true,
  selectedDate: '',
  deliveryAddress: {},
  validateError: null,
  deliveryAddressValid: false,
  validateAddressIsLoading: false,
  validateDeliveryRangeIsLoading: false,
  specialInstructionMessage: '',
  bypassBusinessRules: false,
  dateIsToday: false,
  timeShouldClear: false,
  resetTimeFormValues: () => {},
  timeZone: '',
  leadTimeWarning: {},
  showWarningModal: false,
  closeWarningModal: () => {},
  deliveryRange: 0,
  hoursOfOperation: {},
  specialEvents: [],
  availableLocations: [],
  updateUserLocation: () => {},
  lookupLocation: () => {},
  autocompleteAddress: () => {},
  getPastDeliveryAddresses: () => {},
  pastOrders: [],
  pastDeliveryAddresses: [],
  pastDeliveryAddressesLoading: false,
  noPastDeliveryAddresses: false,
  changeDestination: () => {},
  cateringReason: '',
  clearUserAddressFields: () => {},
  deliveryAccepted: false,
  pickupAccepted: false,
  addSecondaryContact: () => {},
  secondaryContactValues: {},
};

function mapStateToProps(state) {
  return {
    destination: selectDestination(state),
    availableDates: selectAvailableDates(state),
    availableDatesLoading: selectDatesLoading(state),
    selectedDate: selectDate(state),
    dateIsToday: selectDateIsToday(state),
    deliveryAddress: selectDeliveryAddress(state),
    validateError: selectDeliveryError(state),
    deliveryAddressValid: selectDeliveryAddressValid(state),
    validateAddressIsLoading: selectValidateAddressLoading(state),
    validateDeliveryRangeIsLoading: selectValidateDeliveryRangeLoading(state),
    bypassBusinessRules: selectBypassBusinessRules(state),
    timeShouldClear: selectTimeShouldClear(state),
    timeZone: selectLocationTimezone(state),
    leadTimeWarning: selectLeadTimeWarning(state),
    showWarningModal: selectShowWarningModal(state),
    deliveryRange: selectDeliveryRange(state),
    hoursOfOperation: selectHoursOfOperation(state),
    specialEvents: selectSpecialEvents(state),
    availableLocations: selectAvailableLocations(state),
    pastOrders: selectPastOrders(state),
    pastDeliveryAddresses: selectPastDeliveryAddresses(state),
    pastDeliveryAddressesLoading: selectPastDeliveryAddressesLoading(state),
    noPastDeliveryAddresses: selectNoPastDeliveryAddresses(state),
    cateringReason: selectCateringOccasion(state),
    deliveryAccepted: selectLocationAcceptsDelivery(state),
    pickupAccepted: selectLocationAcceptsPickup(state),
    secondaryContactValues: selectSecondaryContactValues(state),
    specialInstructionMessage: selectSpecialInstructionMessage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...orderActions,
      ...formActions,
      ...userActions,
      ...guestActions,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(EventDetails));
