import PropTypes from 'prop-types';
import styled from 'styled-components';

import constants from '../../constants';
import Icon from '../Icon';
import Tooltip from '../Tooltip/Tooltip';

function Loyalty({ cfaLoyalty, showText }) {
  return (
    <StyledLoyalty data-cy="loyalty">
      <Tooltip
        tooltipContent={
          <>
            <Icon className="loyalty-icon">{cfaLoyalty}</Icon>
            <div className="loyalty-tier-tooltip">{`${cfaLoyalty} Member`}</div>
            <div className="loyalty-text-tooltip">
              {constants.GET_LOYALTY[cfaLoyalty]}
            </div>
          </>
        }
      >
        <Icon className="loyalty-icon">{cfaLoyalty}</Icon>
        {showText && (
          <div className="loyalty-text">{`${cfaLoyalty} Member`}</div>
        )}
      </Tooltip>
    </StyledLoyalty>
  );
}

const StyledLoyalty = styled('div')`
  & .loyalty-text {
    font-size: 14px;
    text-align: left;
  }
  & .loyalty-icon {
    width: 21px;
    height: 21px;
    margin: 0 5px 0 0;
  }
  & .loyalty-tier-icon {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }
  & .loyalty-tier-tooltip {
    font: ${(props) => props.theme.regularBoldFont};
    margin: 10px;
  }
  & .loyalty-text-tooltip {
    margin: 5px;
    font: ${(props) => props.theme.regularTextFont};
  }
`;

Loyalty.propTypes = {
  cfaLoyalty: PropTypes.string.isRequired,
  showText: PropTypes.bool,
};

Loyalty.defaultProps = {
  showText: false,
};

export default Loyalty;
