import PropTypes from 'prop-types';
import styled from 'styled-components';

import Disclaimer from '../Disclaimer/Disclaimer';
import Icon from '../Icon';

function LeadTimeWarning({ message }) {
  return (
    <StyledLeadTimeWarning>
      <Icon margin="0 2px 2px 0" width="17px">
        warning
      </Icon>
      <div>{message}</div>
    </StyledLeadTimeWarning>
  );
}

LeadTimeWarning.propTypes = {
  message: PropTypes.string,
};

LeadTimeWarning.defaultProps = {
  message: '',
};

const StyledLeadTimeWarning = styled(Disclaimer)`
  display: flex;
  align-items: center;
  height: 30px;
`;

export default LeadTimeWarning;
