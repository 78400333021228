/* eslint-disable react/forbid-prop-types */
import { Button, TextField } from '@cfa/react-components';
import { Box, Select } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { contains, groupBy } from 'ramda';
import { useState } from 'react';
import styled from 'styled-components';

import ooeConstants from '../../constants';
import { notifyBugsnag } from '../../services/bugsnag';
import { debounce } from '../../util/utils';
import Disclaimer from '../Disclaimer/Disclaimer';
import StyledMenuItem from '../MenuItem/MenuItem';
import PromoFreeToggle from '../PromoFreeToggle/PromoFreeToggle';

function toggleable(mod) {
  const { tag } = mod;
  return contains(tag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
}

function sauceDisclaimer(freeSauces, quantity) {
  if (freeSauces) {
    return (
      <Disclaimer className="sauce-disclaimer">
        {`${freeSauces * quantity} Free Sauce(s)`}
      </Disclaimer>
    );
  }
  return null;
}

function EditCartItem({
  itemTag,
  freeSauces,
  specialInstructions,
  editableItems,
  deleteItem,
  addModifier,
  updateSpecialInstructions,
  updateModifierQuantity,
  quantity,
  sideItems,
  updateSideItem,
  selectedSide,
  dessertItems,
  updateDessertItem,
  selectedDessert,
  cartItem,
  addToCart,
  id,
  toggleEditMode,
  makePromoFree,
  removePromoFree,
  isPromoFree,
  isVca,
}) {
  const [isToggledPromoFree, setTogglePromoFree] = useState(isPromoFree);
  const update = debounce((text) => {
    updateSpecialInstructions(text);
  }, 300);
  const sidesObj = groupBy((sideItem) => sideItem.tag)(sideItems);
  const dessertsObj = groupBy((dessertItem) => dessertItem.tag)(dessertItems);

  const togglePromoFreeHandler = (e) => {
    if (e.target.checked === true) {
      setTogglePromoFree(!isToggledPromoFree);
      makePromoFree(id);
    } else {
      setTogglePromoFree(!isToggledPromoFree);
      removePromoFree(id);
    }
  };

  const handleSideItemChange = (event, sides, item) => {
    try {
      updateSideItem(id, sides[event.target.value][0], item);
    } catch (error) {
      notifyBugsnag('Edit Side Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          sides,
          item,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleDessertItemChange = (event, desserts, item) => {
    try {
      updateDessertItem(id, desserts[event.target.value][0], item);
    } catch (error) {
      notifyBugsnag('Edit Dessert Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          desserts,
          item,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleAddAnotherClicked = () => {
    addToCart(cartItem, true);
    toggleEditMode();
  };

  return (
    <StyledEditCartItem data-testid="cart-item-edit-box">
      {sauceDisclaimer(freeSauces, quantity)}
      <div className="mods">
        {editableItems
          .filter((subItem) => subItem.tag !== 'CARAMEL')
          .map((mod) => (
            <StyledMenuItem
              addToCart={(e) => addModifier(id, e, { comboTag: mod.comboTag })}
              className="menu-item"
              hidePricing
              imageSize="sm"
              item={mod}
              key={mod.tag}
              quantity={mod.quantity}
              recipe={mod.modifierType === 'RECIPE'}
              toggleable={toggleable(mod)}
              updateQuantity={(qty) =>
                updateModifierQuantity(id, mod, qty, { comboTag: mod.comboTag })
              }
            />
          ))}
      </div>
      <Box>
        {sideItems.length > 0 && (
          <Select
            data-cy={`${itemTag}-side`}
            onChange={(e) => handleSideItemChange(e, sidesObj, itemTag)}
            value={selectedSide.tag}
            width="calc(100% - 20px)"
          >
            {sideItems.map((side) => (
              <option data-value={side} key={side.tag} value={side.tag}>
                {side.name}
              </option>
            ))}
          </Select>
        )}
      </Box>
      <Box>
        {dessertItems.length > 0 && (
          <Select
            data-cy={`${itemTag}-side`}
            onChange={(e) => handleDessertItemChange(e, dessertsObj, itemTag)}
            value={selectedDessert.tag}
            width="calc(100% - 20px)"
          >
            {dessertItems.map((dessert) => (
              <option
                data-value={dessert}
                key={dessert.tag}
                value={dessert.tag}
              >
                {dessert.name}
              </option>
            ))}
          </Select>
        )}
      </Box>
      {!isVca && (
        <PromoFreeToggle
          id={id}
          isToggledPromoFree={isToggledPromoFree}
          mr="10px"
          togglePromoFreeHandler={togglePromoFreeHandler}
        />
      )}
      <TextField
        className="special-instructions"
        defaultValue={specialInstructions}
        maxLength={40}
        multiline
        onChange={(e) => update(e.target.value)}
        placeholder="Special Instructions"
      />
      <ButtonContainer>
        <Button
          className="add-another"
          color="secondary"
          data-cy={`split-${itemTag}`}
          fullWidth
          onClick={handleAddAnotherClicked}
          variant="outlined"
        >
          Add Another & Customize
        </Button>
        <Button
          className="delete-item"
          data-cy={`delete-${itemTag}`}
          fullWidth
          onClick={deleteItem}
          variant="destructive"
        >
          Delete Item
        </Button>
      </ButtonContainer>
    </StyledEditCartItem>
  );
}

const StyledEditCartItem = styled.div`
  margin-top: 20px;
  & .mods {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  & .sauce-info {
    width: 100%;
    text-align: left;
    margin: 5px 12px;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }

  & .sauce-disclaimer {
    text-align: center;
    margin-bottom: 15px;
  }

  & .special-instructions {
    height: 60px;
  }
  & .promo-free-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.phone}) {
    flex-direction: column;
  }

  & button {
    margin: 8px;
  }
`;

EditCartItem.propTypes = {
  freeSauces: PropTypes.number,
  itemTag: PropTypes.string.isRequired,
  specialInstructions: PropTypes.string,
  editableItems: PropTypes.arrayOf(PropTypes.object),
  deleteItem: PropTypes.func,
  addModifier: PropTypes.func,
  addToCart: PropTypes.func,
  toggleEditMode: PropTypes.func,
  updateSpecialInstructions: PropTypes.func,
  updateModifierQuantity: PropTypes.func,
  quantity: PropTypes.number,
  id: PropTypes.string,
  sideItems: PropTypes.arrayOf(PropTypes.object),
  selectedSide: PropTypes.objectOf(PropTypes.any),
  dessertItems: PropTypes.arrayOf(PropTypes.object),
  selectedDessert: PropTypes.objectOf(PropTypes.any),
  cartItem: PropTypes.objectOf(PropTypes.any),
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isPromoFree: PropTypes.bool,
  isVca: PropTypes.bool,
};

EditCartItem.defaultProps = {
  cartItem: {},
  editableItems: [],
  specialInstructions: '',
  deleteItem: () => {},
  addModifier: () => {},
  addToCart: () => {},
  updateModifierQuantity: () => {},
  updateSpecialInstructions: () => {},
  toggleEditMode: () => {},
  quantity: 0,
  selectedSide: {},
  sideItems: [],
  selectedDessert: {},
  dessertItems: [],
  freeSauces: 0,
  id: '0',
  isPromoFree: false,
  makePromoFree: () => {},
  removePromoFree: () => {},
  isVca: false,
};

export default EditCartItem;
