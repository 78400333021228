/* eslint-disable react/no-children-prop */

import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';
import styled from 'styled-components';

import Tab from './Tab';

const NavTab = (props) => {
  const { icon, to, activeOnlyWhenExact, title } = props;
  return (
    <StyledNavTab>
      <Route
        children={({ match }) => (
          <NavLink to={{ pathname: to, title }}>
            <Tab className="tab" selected={match}>
              <div className="icon">{icon}</div>
              {title}
            </Tab>
          </NavLink>
        )}
        exact={activeOnlyWhenExact}
        path={to}
      />
    </StyledNavTab>
  );
};

const StyledNavTab = styled.div`
  flex: 1;
  text-decoration: none;

  & .icon svg {
    margin: 8px 0 0 0;
    max-width: 35px;
    max-height: 35px;
  }

  @media (min-width: ${(props) => props.theme.phone}) {
    & .icon {
      display: none;
    }
  }
`;

NavTab.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
};

NavTab.defaultProps = {
  title: '',
  to: '',
  activeOnlyWhenExact: false,
  icon: null,
};

export default NavTab;
