import PropTypes from 'prop-types';

class BugsnagErrorBoundaryPluginHelper {
  static plugin = null;

  static get BugsnagErrorBoundaryPlugin() {
    return this.plugin;
  }

  static set BugsnagErrorBoundaryPlugin(val) {
    this.plugin = val;
  }
}

const BugsnagErrorBoundary = ({ FallbackComponent, children }) => {
  const Plugin = BugsnagErrorBoundaryPluginHelper.BugsnagErrorBoundaryPlugin;
  return Plugin ? (
    <Plugin FallbackComponent={FallbackComponent}>{children}</Plugin>
  ) : (
    children
  );
};

BugsnagErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.func.isRequired,
  children: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.symbol, PropTypes.object, PropTypes.func]),
  ).isRequired,
};

export { BugsnagErrorBoundary as default, BugsnagErrorBoundaryPluginHelper };
