import { curry, filter, pluck, prop } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';

import { selectIndexedSellableItems } from '../reducers';
import { actions as cartActions, selectCart } from '../reducers/cart';
import { types as menuTypes } from '../reducers/menu';
import { types as orderTypes } from '../reducers/order';
import { types as userTypes } from '../reducers/user';

const isCartItemNotInMenu = curry(
  (indexedMenu, { tag }) => prop(tag, indexedMenu) === undefined,
);

export const RemoveNonExistentCartItems = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.CHANGE_DESTINATION, userTypes.UPDATE_USER_LOCATION),
    switchMap((action) => {
      if (action.type === userTypes.UPDATE_USER_LOCATION) {
        return action$.pipe(
          ofType(menuTypes.MENU_SUCCESS),
          take(1),
          switchMap(() => action$.pipe(ofType(menuTypes.MENU_SUCCESS))),
          mapTo(action),
        );
      }
      return of(action);
    }),
    map(() => {
      const state = store.value;
      const cart = selectCart(state);
      const menu = selectIndexedSellableItems(state);
      return filter(isCartItemNotInMenu(menu), cart);
    }),
    map(pluck('tag')),
    map(cartActions.removeNonExistentItems),
  );

export default [RemoveNonExistentCartItems];
