import { addMinutes, format, parse } from 'date-fns';
import leftPad from 'left-pad';
import {
  contains,
  find,
  isNil,
  path,
  pathOr,
  pluck,
  prop,
  propEq,
} from 'ramda';
import { createSelector } from 'reselect';

import ooeConstants from '../constants';
import FeatureFlagService from '../services/featureFlags';
import makeActionCreator from '../util/makeActionCreator';
import { getQueryVariable } from '../util/routerHelpers';

export const types = {
  USER_INFO_REQUEST: '[User] User Info Request',
  USER_INFO_SUCCESS: '[User] User Info Success',
  USER_INFO_FAILURE: '[User] User Info Failure',
  GET_TOKEN_FROM_STORAGE: '[User] Get token from storage',
  GET_TOKEN_FROM_STORAGE_SUCCESS: '[User] Get token from storage success',
  GET_OKTA_TOKEN: '[User] Get okta token',
  GET_OKTA_TOKEN_SUCCESS: '[User] Get okta token success',
  GET_OKTA_TOKEN_FAILURE: '[User] Get okta token failure',
  PROCESS_OKTA_TOKEN_SUCCESS: '[User] Exchange okta token success',
  PROCESS_OKTA_TOKEN_FAILURE: '[User] Exchange okta token failure',
  GET_USER_LOCATIONS_SUCCESS: '[User] Get locations success',
  GET_USER_LOCATIONS_FAILURE: '[User] Get locations failure',
  UPDATE_USER_LOCATION: '[User] Update user location',
  LOGOUT_USER: '[User] Logout',
  MESSAGE_ACKNOWLEDGED_SUCCESS: '[User] Message Acknowledged success',
  MESSAGE_ACKNOWLEDGED_FAILURE: '[User] Message Acknowledged failure',
  MESSAGE_TEXT_SUCCESS: '[User] Message text success',
  MESSAGE_TEXT_FAILURE: '[User] Message text failure',
  ADD_USER_TO_ACKNOWLEDGEMENT: '[User] Add User to acknowledgement',
  ADD_USER_TO_ACKNOWLEDGEMENT_SUCCESS:
    '[User] Add User to acknowledgement success',
  FULL_SCREEN_ERROR: '[User] Full Screen Error',
  REFRESH_TOKEN: '[User] Refresh Token',
  REFRESH_TOKEN_SUCCESS: '[User] Refresh Token Success',
  REFRESH_TOKEN_FAILURE: '[User] Refresh Token Failure',
  TOGGLE_BYPASS_BUSINESS_RULES: '[User] Toggle Bypass',
  LOOKUP_LOCATION: '[User] Lookup Location',
  LOOKUP_LOCATION_SUCCESS: '[User] Lookup Location Success',
  LOOKUP_LOCATION_FAILURE: '[User] Lookup Location Failure',
  SET_BBR_FOR_VCA: '[User] Set Bypeass Business Rules For VCA',
  GET_STORE_NUMBERS_AND_NAMES_SUCCESS:
    '[User] Get store names and numbers success',
  GET_STORE_NUMBERS_AND_NAMES_FAILURE:
    '[User] Get store names and numbers failure',
  SHOW_HARDCODED_EULA_TEXT: '[User] Add hardcoded EULA text',
  ACKNOWLEDGEMENT_SUCCESS_STORAGE:
    '[User] Get user agreed to EULA from storage success',
};

export const keys = {
  OKTA_TOKEN: 'oktaToken',
  REFRESH_OKTA: 'refreshOkta',
  PROCESS_OKTA: 'processOkta',
  LOCATIONS: 'locations',
};

export const actions = makeActionCreator({
  getTokenFromStorage: () => ({
    type: types.GET_TOKEN_FROM_STORAGE,
  }),
  getTokenFromStorageSuccess: (oktaPayload) => ({
    type: types.GET_TOKEN_FROM_STORAGE_SUCCESS,
    oktaPayload,
  }),
  getOktaToken: () => ({ type: types.GET_OKTA_TOKEN, key: keys.OKTA_TOKEN }),
  getOktaTokenSuccess: (oktaPayload = {}) => ({
    type: types.GET_OKTA_TOKEN_SUCCESS,
    key: keys.OKTA_TOKEN,
    oktaPayload,
  }),
  getOktaTokenFailure: (error) => ({
    type: types.GET_OKTA_TOKEN_FAILURE,
    key: keys.OKTA_TOKEN,
    error,
  }),
  processOktaTokenSuccess: (user) => ({
    type: types.PROCESS_OKTA_TOKEN_SUCCESS,
    key: keys.PROCESS_OKTA,
    user,
  }),
  processOktaTokenFailure: (error) => ({
    type: types.PROCESS_OKTA_TOKEN_FAILURE,
    key: keys.PROCESS_OKTA,
    error,
  }),
  refreshOktaToken: () => ({
    type: types.REFRESH_TOKEN,
    key: keys.REFRESH_OKTA,
  }),
  refreshTokenSuccess: (user) => ({
    type: types.REFRESH_TOKEN_SUCCESS,
    key: keys.REFRESH_OKTA,
    user,
  }),
  refreshTokenFailure: () => ({
    type: types.REFRESH_TOKEN_FAILURE,
    key: keys.REFRESH_OKTA,
  }),
  getUserLocationsSuccess: (locations) => ({
    type: types.GET_USER_LOCATIONS_SUCCESS,
    key: keys.LOCATIONS,
    locations,
  }),
  getUserLocationsFailure: (error) => ({
    type: types.GET_USER_LOCATIONS_FAILURE,
    key: keys.LOCATIONS,
    error,
  }),
  lookupLocation: (location) => ({
    type: types.LOOKUP_LOCATION,
    location,
  }),
  lookupLocationSuccess: (location) => ({
    type: types.LOOKUP_LOCATION_SUCCESS,
    key: keys.LOCATIONS,
    location,
  }),
  lookupLocationFailure: (error) => ({
    type: types.LOOKUP_LOCATION_FAILURE,
    key: keys.LOCATIONS,
    error,
  }),
  updateUserLocation: (location) => ({
    type: types.UPDATE_USER_LOCATION,
    location,
  }),
  logoutUser: () => ({ type: types.LOGOUT_USER }),
  messageAcknowledgedSuccess: (key, acknowledged) => ({
    type: types.MESSAGE_ACKNOWLEDGED_SUCCESS,
    acknowledged,
    key,
  }),
  messageAcknowledgedFailure: (key, error) => ({
    type: types.MESSAGE_ACKNOWLEDGED_FAILURE,
    error,
    key,
  }),
  messageTextSuccess: (key, text) => ({
    type: types.MESSAGE_TEXT_SUCCESS,
    text,
    key,
  }),
  messageTextFailure: (key, error) => ({
    type: types.MESSAGE_TEXT_FAILURE,
    error,
    key,
  }),
  addUserToAcknowledgement: (key) => ({
    type: types.ADD_USER_TO_ACKNOWLEDGEMENT,
    key,
  }),
  addUserToAcknowledgementSuccess: (key) => ({
    type: types.ADD_USER_TO_ACKNOWLEDGEMENT_SUCCESS,
    key,
  }),
  addUserToAcknowledgementSuccessStorage: (key) => ({
    type: types.ACKNOWLEDGEMENT_SUCCESS_STORAGE,
    key,
  }),
  throwFullScreenError: () => ({ type: types.FULL_SCREEN_ERROR }),
  setBBRForVCA: (isVca) => ({ type: types.SET_BBR_FOR_VCA, isVca }),
  toggleBypass: () => ({ type: types.TOGGLE_BYPASS_BUSINESS_RULES }),
  getStoreNumbersAndNamesSuccess: (storeNamesAndNumbers) => ({
    type: types.GET_STORE_NUMBERS_AND_NAMES_SUCCESS,
    storeNamesAndNumbers,
  }),
  getStoreNumbersAndNamesFailure: (error) => ({
    type: types.GET_STORE_NUMBERS_AND_NAMES_FAILURE,
    error,
  }),
  showHardcodedEulaText: () => ({ type: types.SHOW_HARDCODED_EULA_TEXT }),
});

export const initialState = {
  locations: [],
  selectedLocation: '',
  loading: {
    oktaToken: true,
    cmtEula: true,
  },
  error: {},
  shouldBypassBusinessRules: true,
  storeNamesAndNumbers: [],
  showHardcodedMessage: false,
  defaultLocation:
    localStorage.getItem(ooeConstants.LOCAL_STORAGE_DEFAULT_LOCATION) || '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOKEN_FROM_STORAGE:
    case types.GET_OKTA_TOKEN: {
      return {
        ...state,
        loading: {
          ...state.loading,
          oktaToken: true,
        },
      };
    }
    case types.GET_TOKEN_FROM_STORAGE_SUCCESS: {
      const { oktaPayload } = action;
      return {
        ...state,
        ...oktaPayload,
        error: { ...state.error, oktaToken: null },
        loading: {
          ...state.loading,
          [keys.OKTA_TOKEN]: false,
        },
      };
    }

    case types.GET_OKTA_TOKEN_SUCCESS: {
      const { oktaPayload } = action;
      const { idToken } = oktaPayload;
      return {
        ...state,
        idToken,
        error: { ...state.error, oktaToken: null },
        loading: {
          ...state.loading,
          [keys.OKTA_TOKEN]: false,
          [keys.PROCESS_OKTA]: true,
        },
      };
    }

    case types.REFRESH_TOKEN: {
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
      };
    }
    case types.PROCESS_OKTA_TOKEN_SUCCESS:
    case types.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        ...action.user,
        error: {
          ...state.error,
          [keys.REFRESH_OKTA]: null,
          [keys.PROCESS_OKTA]: null,
        },
        loading: {
          ...state.loading,
          [keys.REFRESH_OKTA]: false,
          [keys.PROCESS_OKTA]: false,
          [keys.OKTA_TOKEN]: false,
          locations: true,
          cmtEula: window.Cypress ? false : state.loading.cmtEula,
          eula: window.Cypress ? false : state.loading.eula,
        },
      };
    }

    case types.SET_BBR_FOR_VCA: {
      const { isVca } = action;
      return {
        ...state,
        shouldBypassBusinessRules: !isVca,
      };
    }

    case types.GET_USER_LOCATIONS_SUCCESS: {
      let foundLocationNumber;
      const [initialLocation] = action.locations;
      let initialLocationNumber = initialLocation.locationNumber;
      const queryLocationNumber = getQueryVariable('locationNumber');

      if (queryLocationNumber) {
        const paddedLocationNumber = leftPad(queryLocationNumber, 5, 0);
        const foundLocation = find(
          propEq('locationNumber', paddedLocationNumber),
        )(action.locations);
        foundLocationNumber = prop('locationNumber', foundLocation);
      }

      let previouslySelectedValidLocation;
      if (
        state.selectedLocation &&
        find(propEq('locationNumber', state.selectedLocation), action.locations)
      ) {
        previouslySelectedValidLocation = state.selectedLocation;
      }

      const { defaultLocation } = state;
      if (
        defaultLocation &&
        contains(defaultLocation, pluck('locationNumber', action.locations))
      ) {
        initialLocationNumber = defaultLocation;
      }

      const selectedLocation =
        previouslySelectedValidLocation ||
        foundLocationNumber ||
        initialLocationNumber;

      if (defaultLocation !== selectedLocation) {
        localStorage.setItem(
          ooeConstants.LOCAL_STORAGE_DEFAULT_LOCATION,
          selectedLocation,
        );
      }

      return {
        ...state,
        locations: action.locations,
        selectedLocation,
        defaultLocation: selectedLocation,
        error: { ...state.error, locations: null },
        loading: {
          ...state.loading,
          locations: false,
        },
      };
    }

    case types.GET_OKTA_TOKEN_FAILURE:
    case types.PROCESS_OKTA_TOKEN_FAILURE:
    case types.REFRESH_TOKEN_FAILURE:
    case types.GET_USER_LOCATIONS_FAILURE:
    case types.LOOKUP_LOCATION_FAILURE: {
      const { key, error } = action;
      return {
        ...state,
        error: { ...state.error, [key]: error },
        loading: { ...state.loading, [key]: false },
      };
    }

    case types.UPDATE_USER_LOCATION: {
      const { location } = action;
      localStorage.setItem(
        ooeConstants.LOCAL_STORAGE_DEFAULT_LOCATION,
        location,
      );
      return {
        ...state,
        selectedLocation: location,
        defaultLocation: location,
        error: { ...state.error, locations: null },
      };
    }

    case types.LOGOUT_USER: {
      return {
        ...state,
        idToken: '',
        accessToken: '',
      };
    }

    case types.MESSAGE_ACKNOWLEDGED_SUCCESS: {
      const { acknowledged, key } = action;
      // Empty string means user has not agreed to message
      if (acknowledged === '') {
        return {
          ...state,
          [key]: false,
          loading: { ...state.loading, [key]: false },
        };
      }
      return {
        ...state,
        [key]: true,
        loading: { ...state.loading, [key]: false },
      };
    }

    case types.MESSAGE_TEXT_SUCCESS: {
      const { key } = action;
      const content = pathOr('', ['text', 'content'], action);
      const textKey = `${key}Text`;
      return {
        ...state,
        [textKey]: content,
        loading: { ...state.loading, [key]: false },
      };
    }

    case types.SHOW_HARDCODED_EULA_TEXT: {
      return {
        ...state,
        cmtEulaText: ooeConstants.EULA_MESSAGE,
        showHardcodedMessage: true,
      };
    }

    case types.ADD_USER_TO_ACKNOWLEDGEMENT: {
      const { key } = action;
      if (key === ooeConstants.LOCAL_STORAGE_EULA) {
        localStorage.setItem(ooeConstants.EULA, true);
        return {
          ...state,
          cmtEula: true,
        };
      }
      return {
        ...state,
      };
    }

    case types.ADD_USER_TO_ACKNOWLEDGEMENT_SUCCESS:
    case types.ACKNOWLEDGEMENT_SUCCESS_STORAGE: {
      const { key } = action;
      return {
        ...state,
        [key]: true,
        loading: { ...state.loading, [key]: false },
        error: { ...state.loading, [key]: null },
      };
    }

    case types.MESSAGE_TEXT_FAILURE:
    case types.MESSAGE_ACKNOWLEDGED_FAILURE: {
      const { error, key } = action;
      return {
        ...state,
        [key]: false,
        error: { ...state.error, [key]: error },
        loading: { ...state.loading, [key]: false },
      };
    }

    case types.TOGGLE_BYPASS_BUSINESS_RULES: {
      return {
        ...state,
        shouldBypassBusinessRules: !state.shouldBypassBusinessRules,
      };
    }

    case types.LOOKUP_LOCATION_SUCCESS: {
      const { location, key } = action;
      return {
        ...state,
        locations: [...state.locations, ...location],
        error: { ...state.error, [key]: null },
        loading: { ...state.loading, [key]: false },
        selectedLocation: location.length
          ? pathOr('00000', ['locationNumber'], location[0])
          : '00000',
      };
    }

    case types.GET_STORE_NUMBERS_AND_NAMES_SUCCESS: {
      const { storeNamesAndNumbers } = action;
      return {
        ...state,
        storeNamesAndNumbers: storeNamesAndNumbers.filter(
          (store) => store.status === 'open',
        ),
      };
    }

    case types.GET_STORE_NUMBERS_AND_NAMES_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error: { ...state.error, storeNamesAndNumbers: error },
      };
    }

    case types.SPECIAL_LOCATION_ERROR_MESSAGE: {
      const { error, key } = action;
      return {
        ...state,
        error: { ...state.error, [key]: error },
      };
    }

    default:
      return state;
  }
};

export const selectUser = (state) => state?.user || {};

export const selectShouldBypassBusinessRules = createSelector(
  selectUser,
  (user) => user.shouldBypassBusinessRules,
);

export const selectCmtPerm = createSelector(selectUser, (user) => {
  // get the object that should be holding the user's CMT permission as a key
  const cmtUserPerm = pathOr({}, ['cfa_perms', 'CMT'], user);
  // convert that object to an array and pull off first key (the permission level) if exists
  const permLevels = Object.keys(cmtUserPerm);
  if (permLevels.length > 0) {
    return permLevels[0];
  }
  return '';
});

export const selectCmtPerms = createSelector(selectUser, (user) => {
  // get the object that should be holding the user's CMT permission as a key
  const cmtUserPerm = pathOr({}, ['cfa_perms', 'CMT'], user);
  // convert that object to an array and pull off first key (the permission level) if exists
  const permLevels = Object.keys(cmtUserPerm);
  return permLevels;
});

export const isAdminUser = createSelector(
  selectCmtPerm,
  (perm) => perm === ooeConstants.PERMISSIONS.ADMIN,
);

export const isVcaUser = createSelector(selectCmtPerms, (perms) =>
  [ooeConstants.PERMISSIONS.VCA, ooeConstants.PERMISSIONS.VCA_BYPASS].some(
    (item) => perms.includes(item),
  ),
);

export const selectUserInfo = createSelector(
  selectUser,
  (user) => user.profile,
);
export const selectUserName = createSelector(
  selectUser,
  (user) => `${user.nickname || ''} ${user.family_name || ''}`,
);
export const selectUserEmail = createSelector(
  selectUser,
  (user) => user.email ?? '',
);
export const selectUserCfaGuid = createSelector(
  selectUser,
  (user) => user.cfa_guid ?? '',
);
export const selectOktaToken = createSelector(
  selectUser,
  (user) => user.idToken,
);
export const selectAccessToken = createSelector(
  selectUser,
  (user) => user.accessToken,
);
export const selectRefreshToken = createSelector(
  selectUser,
  (user) => user.refreshToken,
);
export const selectAccessTokenExpirationDate = createSelector(
  selectUser,
  (user) => user.exp,
);
export const selectUserLocationNumbers = createSelector(
  selectUser,
  selectCmtPerm,
  (user, perm) => user.cfa_perms.CMT[perm],
);
export const selectLocationNumber = createSelector(
  selectUser,
  (user) => user.selectedLocation,
);
export const selectUserLocations = createSelector(
  selectUser,
  (user) => user.locations,
);
export const selectLocationDropdownDisabled = createSelector(
  selectUserLocations,
  (locations) => locations.length <= 1,
);
export const selectLocationDetails = createSelector(
  selectUserLocations,
  selectLocationNumber,
  (locationArray, selectedNumber) =>
    locationArray.filter(
      (location) => location.locationNumber === selectedNumber,
    )[0] || {},
);
export const selectLocationTimezone = createSelector(
  selectLocationDetails,
  (locationDetails) =>
    pathOr(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      ['timeZone'],
      locationDetails,
    ),
);
export const selectLocationContactDetails = createSelector(
  selectLocationDetails,
  selectLocationNumber,
  (details, locationNumber) => {
    const restaurantName = pathOr('', ['name'], details);
    const restaurantAddress1 = pathOr(
      '',
      ['locationContact', 'streetAddress', 'address1'],
      details,
    );
    const restaurantAddress2 = `${pathOr('', ['locationContact', 'streetAddress', 'city'], details)}, ${pathOr('', ['locationContact', 'streetAddress', 'state'], details)} ${pathOr('', ['locationContact', 'streetAddress', 'zipCode', 'zip'], details)}`;
    const restaurantPhone = `${pathOr('', ['locationContact', 'daytimePhone', 'areaCode'], details)}-${pathOr('', ['locationContact', 'daytimePhone', 'phoneNumber'], details)}`;

    let phoneNumber;
    switch (locationNumber) {
      case '03606':
        phoneNumber = ooeConstants.HARDCODED_PHONE_FOR_03606;
        break;
      case '02891':
        phoneNumber = ooeConstants.HARDCODED_PHONE_FOR_02891;
        break;
      case '04302':
        phoneNumber = ooeConstants.HARDCODED_PHONE_FOR_04302;
        break;
      case '03724':
        phoneNumber = ooeConstants.HARDCODED_PHONE_FOR_03724;
        break;
      default:
        phoneNumber = restaurantPhone;
    }

    return {
      restaurantName,
      restaurantAddress1,
      restaurantAddress2,
      restaurantPhone: phoneNumber,
    };
  },
);
export const selectAppLoadingComplete = createSelector(
  selectUser,
  (user) =>
    !user.loading.oktaToken &&
    !user.loading.processOkta &&
    !user.loading.locations &&
    !user.loading.cmtEula,
);
export const selectUserHasAgreedToEula = createSelector(
  selectUser,
  (user) => user.cmtEula || !!localStorage.getItem('cmtEula'),
);
export const selectEulaText = createSelector(selectUser, (user) =>
  pathOr('', ['cmtEulaText'], user),
);
export const selectShowHardcodedEulaText = createSelector(
  selectUser,
  (user) => user.showHardcodedMessage,
);

export const selectLocationMOSettings = createSelector(
  selectLocationDetails,
  (details) => details?.mobileOrderingSettings,
);

export const selectCateringDeliveryMOSettings = createSelector(
  selectLocationMOSettings,
  (settings) => settings?.fulfillmentMethods?.cateringDelivery,
);

export const selectCateringPickupMOSettings = createSelector(
  selectLocationMOSettings,
  (settings) => settings?.fulfillmentMethods?.cateringPickup,
);

export const selectLocationMOSettingsFulfillmentMethods = createSelector(
  selectLocationMOSettings,
  (settings) => settings?.fulfillmentMethods,
);

export const selectLocationAcceptsDelivery = createSelector(
  selectLocationMOSettingsFulfillmentMethods,
  (settings) => !!settings?.cateringDelivery,
);

export const selectLocationAcceptsPickup = createSelector(
  selectLocationMOSettingsFulfillmentMethods,
  (settings) => !!settings?.cateringPickup,
);

export const selectMinDeliveryAmount = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.orderMinAmountCents,
);

export const selectMinPickupAmount = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.orderMinAmountCents,
);

export const selectMaxDeliveryAmount = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.orderMaxAmountCents,
);

export const selectMaxPickupAmount = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.orderMaxAmountCents,
);

export const selectMaxSameDayOrderTotalAmountCents = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.maxSameDayOrderTotalAmountCents,
);

//this is hidden from the UI
export const selectOrderMaxFutureDayCountDelivery = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.orderMaxFutureDayCount,
);

//this is hidden from the UI
export const selectOrderMaxFutureDayCountPickup = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.orderMaxFutureDayCount,
);

export const selectDeliveryRange = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.maxDeliveryRangeMiles,
);

export const selectDeliveryLeadTimeStoreInfo = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.leadTimeMinutes,
);

export const selectPickupLeadTimeStoreInfo = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.leadTimeMinutes,
);

export const selectDeliveryLeadTime = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => {
    if (isNil(settings?.leadTimeMinutes) || settings?.leadTimeMinutes === 0) {
      return ooeConstants.NO_DELIVERY_LEAD_TIME;
    }
    return `${settings.leadTimeMinutes / 60} hr delivery lead time`;
  },
);

export const selectPickupLeadTime = createSelector(
  selectCateringPickupMOSettings,
  (settings) => {
    if (isNil(settings?.leadTimeMinutes) || settings?.leadTimeMinutes === 0) {
      return ooeConstants.NO_PICKUP_LEAD_TIME;
    }
    return `${settings.leadTimeMinutes / 60} hr pickup lead time`;
  },
);

export const selectInStateDeliveryOnly = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.inStateCateringDeliveryOnly,
);

export const selectError = createSelector(selectUser, (user) => user.error);
export const selectOktaTokenError = createSelector(selectError, (error) =>
  Boolean(error[keys.OKTA_TOKEN]),
);
export const selectIsUserLoggedIn = createSelector(selectUser, (user) =>
  Boolean(user.cfa_aud),
);
export const selectBypassBusinessRules = (state) => {
  if (selectShouldBypassBusinessRules(state)) {
    return true;
  }
  if (FeatureFlagService.variation('bypass-business-rules', false)) {
    const user = selectUser(state);
    return (
      !path(['cfa_perms', 'CMT', ooeConstants.PERMISSIONS.ADMIN], user) &&
      !path(['cfa_perms', 'CMT', ooeConstants.PERMISSIONS.VCA], user)
    );
  }
  return false;
};

export const doesLocationNumberExist = (state, locationNum) => {
  const allLocationNumbers = state.user.locations.map(
    (loc) => loc.locationNumber,
  );
  const existingLocation = allLocationNumbers.find(
    (num) => num === locationNum,
  );
  return Boolean(existingLocation);
};

export const isDeveloperAudience = (cfaAud) =>
  cfaAud === ooeConstants.CONTRACTOR_AUDIENCE ||
  cfaAud === ooeConstants.STAFF_AUDIENCE;

const pullOutHours = (hoursOfOperation) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday } =
    hoursOfOperation;
  const arrOfDays = [
    { day: 'Monday', hours: monday },
    { day: 'Tuesday', hours: tuesday },
    { day: 'Wednesday', hours: wednesday },
    { day: 'Thursday', hours: thursday },
    { day: 'Friday', hours: friday },
    { day: 'Saturday', hours: saturday },
  ];
  return arrOfDays.map((dayOfWeek) => {
    let blackoutHours = null;
    if (dayOfWeek.hours?.blackoutHours) {
      const { openTime } = dayOfWeek?.hours?.blackoutHours;
      const { durationInMinutes } = dayOfWeek?.hours.blackoutHours;
      const parsedOpenTime = parse(
        openTime,
        ooeConstants.DATE_TIME_FORMAT.time,
        new Date(),
      );
      const formatedOpenTime = format(
        parsedOpenTime,
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      const endTime = format(
        addMinutes(parsedOpenTime, durationInMinutes),
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      blackoutHours = `- ${formatedOpenTime} ${endTime} -`;
    }
    if (dayOfWeek.hours?.operationType === 'closed') {
      return `${dayOfWeek.day}: Closed`;
    }
    if (dayOfWeek.hours?.operationType === 'open24Hours') {
      return `${dayOfWeek.day}: ${blackoutHours ? `12:00 AM ${blackoutHours} 12:00 PM` : 'Open 24h'}`;
    }
    if (dayOfWeek.hours?.operationType === 'standardHours') {
      const { openTime } = dayOfWeek?.hours?.operatingInterval;
      const { durationInMinutes } = dayOfWeek?.hours?.operatingInterval;
      const parsedOpenTime = parse(
        openTime,
        ooeConstants.DATE_TIME_FORMAT.time,
        new Date(),
      );
      const formatedOpenTime = format(
        parsedOpenTime,
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      const endTime = format(
        addMinutes(parsedOpenTime, durationInMinutes),
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      return `${dayOfWeek.day}: ${formatedOpenTime} ${blackoutHours || '-'} ${endTime}`;
    }
    return `${dayOfWeek.day}: Closed`;
  });
};

export const selectRestaurantHoursOfOperation = createSelector(
  selectLocationDetails,
  (details) => {
    const restaurantHours = pathOr({}, ['restaurantHours'], details);
    // eslint-disable-next-line dot-notation
    if (restaurantHours['hoursOfOperation']) {
      const { hoursOfOperation } = restaurantHours;
      return pullOutHours(hoursOfOperation);
    }
    return [];
  },
);

export const selectCateringDeliveryHoursOfOperation = createSelector(
  selectLocationDetails,
  (details) => {
    const cateringDelivery = pathOr(
      {},
      ['mobileOrderingSettings', 'fulfillmentMethods', 'cateringDelivery'],
      details,
    );
    // eslint-disable-next-line dot-notation
    if (cateringDelivery['hoursOfOperation']) {
      const { hoursOfOperation } = cateringDelivery;
      return pullOutHours(hoursOfOperation);
    }
    return [];
  },
);

export const selectAllStoreNamesAndNumbers = createSelector(
  selectUser,
  (user) => user.storeNamesAndNumbers,
);

export const selectLookupLocationError = createSelector(
  selectError,
  (error) => error?.[keys.LOCATIONS] || null,
);

export const selectDefaultLocation = createSelector(
  selectUser,
  (user) => user.defaultLocation,
);
