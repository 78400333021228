import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';

const history = createBrowserHistory();

export const BrowserRouter = ({ children }) => (
  <Router history={history}>{children}</Router>
);
BrowserRouter.propTypes = {
  children: PropTypes.element,
};
BrowserRouter.defaultProps = {
  children: null,
};

export default history;
