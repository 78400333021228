import { combineEpics } from 'redux-observable';

import constants from '../constants';
import AnalyticsEpics from './analytics';
import CartEpics from './cart';
import DashboardEpics from './dashboard';
import DeviceEpics from './device';
import FormEpics from './form';
import GuestEpics from './guest';
import MenuEpics from './menu';
import OrderEpics from './order';
import UserEpics from './user';

let array = [
  ...MenuEpics,
  ...OrderEpics,
  ...UserEpics,
  ...GuestEpics,
  ...FormEpics,
  ...DashboardEpics,
  ...CartEpics,
  ...DeviceEpics,
];

if (!window.Cypress) {
  array = [
    ...array,
    // TODO: Why isn't analytics working locally? GTM issue
    ...(constants.DEV !== 'qa' ? AnalyticsEpics : {}),
  ];
}

export const epicsArray = array;

const combinedEpics = combineEpics(...epicsArray);

export default combinedEpics;
