import { Spinner } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { contains } from 'ramda';
import { memo, useState } from 'react';
import styled from 'styled-components';

import constants from '../../constants';
import FeatureFlagService from '../../services/featureFlags';
import AreYouSure from '../AreYouSure/AreYouSure';
import OrderExportExcel from '../ExportExcel/OrderExportExcel';
import Icon from '../Icon';
import DashboardActionButton from './DashboardActionButton';

const showEditOrderButton = () =>
  FeatureFlagService.variation('edit-order', false);
const showResendEmailButton = () =>
  FeatureFlagService.variation('resend-payment-email', false);

export const DashboardActionsComponent = (props) => {
  const {
    cancelOrder,
    orderDetails,
    resendPaymentEmail,
    initiateEditOrder,
    guestDetails,
    dashboardActionLoading,
    tooLateToCancelOrEdit,
    paymentEmailResent,
    isPOSOrder,
    isErrorStatus,
    restaurantContactDetails,
  } = props;

  const { id, cfaId, email, status } = orderDetails;

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmToShow, setConfirmToShow] = useState({});

  const cancelled = contains(status, constants.CANCELLED);
  const showResendEmail = status === constants.PAYMENT_PENDING;
  const disable = tooLateToCancelOrEdit || isPOSOrder || isErrorStatus;

  const handleCancelOrder = () => {
    cancelOrder(id, cfaId);
    setShowConfirm(false);
  };

  const handleResendPaymentEmail = () => {
    resendPaymentEmail(id, cfaId);
    setShowConfirm(false);
  };

  const handleEditOrder = () => {
    initiateEditOrder(orderDetails, guestDetails);
    setShowConfirm(false);
  };

  const declineConfirm = () => {
    setShowConfirm(false);
    setConfirmToShow({});
  };

  const toggleConfirmMessage = (type) => {
    const confirmTypes = {
      cancelConfirm: {
        message: constants.GET_DASHBOARD_CANCEL_MESSAGES.message,
        confirm: handleCancelOrder,
        confirmText: constants.GET_DASHBOARD_CANCEL_MESSAGES.confirmText,
        decline: declineConfirm,
        declineText: constants.GET_DASHBOARD_CANCEL_MESSAGES.declineText,
      },
      resendEmailConfirm: {
        message: `${constants.GET_DASHBOARD_RESEND_MESSAGES.message} ${email}?`,
        confirm: handleResendPaymentEmail,
        confirmText: constants.GET_DASHBOARD_RESEND_MESSAGES.confirmText,
        decline: declineConfirm,
        declineText: constants.GET_DASHBOARD_RESEND_MESSAGES.declineText,
      },
    };
    setShowConfirm(!showConfirm);
    setConfirmToShow(confirmTypes[type]);
  };
  const { message, confirm, confirmText, decline, declineText } = confirmToShow;

  if (cancelled) {
    return (
      <DashboardActions>
        <div className="action-message" data-testid="action-message">
          <Icon>error</Icon>
          {constants.GET_DASHBOARD_CANCEL_MESSAGES.cancelled}
        </div>
      </DashboardActions>
    );
  }
  if (paymentEmailResent) {
    return (
      <DashboardActions>
        <div className="action-message email" data-testid="action-message">
          <Icon>success</Icon>
          {`${constants.GET_DASHBOARD_RESEND_MESSAGES.emailSent} ${email}`}
        </div>
      </DashboardActions>
    );
  }
  if (dashboardActionLoading) {
    return (
      <DashboardActions>
        <Spinner data-testid="spinner" />
      </DashboardActions>
    );
  }
  return (
    <DashboardActions>
      {showConfirm ? (
        <AreYouSure
          confirm={confirm}
          confirmText={confirmText}
          data-testid="are-you-sure"
          decline={decline}
          declineText={declineText}
          message={message}
        />
      ) : (
        <>
          {showEditOrderButton() && (
            <DashboardActionButton
              buttonText="Edit Order"
              className="edit-order"
              disabled={disable}
              icon="notepad"
              onClick={handleEditOrder}
            />
          )}
          {showResendEmailButton() && showResendEmail && (
            <DashboardActionButton
              buttonText="Resend Payment Email"
              className="payment-email"
              data-cy="resend-button"
              disabled={disable}
              icon="email"
              onClick={() => toggleConfirmMessage('resendEmailConfirm')}
            />
          )}
          <DashboardActionButton
            buttonText="Cancel Order"
            className="cancel-order"
            disabled={disable}
            icon="delete"
            onClick={() => toggleConfirmMessage('cancelConfirm')}
          />
          <OrderExportExcel
            orderDetails={orderDetails}
            restaurantContactDetails={restaurantContactDetails}
          />
        </>
      )}
    </DashboardActions>
  );
};

const DashboardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  padding-bottom: 20px;

  & .action-message {
    height: 98px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  & .action-message svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }

  & .loading {
    width: 134px;
    height: 98px;
    align-self: center;
  }

  & .loading img {
    margin: 28px;
  }

  @media (max-width: 600px) {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`;

DashboardActionsComponent.propTypes = {
  tooLateToCancelOrEdit: PropTypes.bool,
  dashboardActionLoading: PropTypes.bool,
  cancelOrder: PropTypes.func,
  orderDetails: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  resendPaymentEmail: PropTypes.func.isRequired,
  paymentEmailResent: PropTypes.bool,
  isPOSOrder: PropTypes.bool,
  isErrorStatus: PropTypes.bool,
  initiateEditOrder: PropTypes.func,
  guestDetails: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
};

DashboardActionsComponent.defaultProps = {
  tooLateToCancelOrEdit: false,
  dashboardActionLoading: false,
  cancelOrder: () => {},
  orderDetails: {},
  guestDetails: {},
  paymentEmailResent: false,
  isPOSOrder: false,
  isErrorStatus: false,
  initiateEditOrder: () => {},
  restaurantContactDetails: {},
};

export default memo(DashboardActionsComponent);
