const theme = {
  fontFamily: 'Apercu',
  smallTextFont: 'normal 12px Apercu, sans-serif',
  smallBoldFont: 'normal bold 13px Apercu, sans-serif',
  regularTextFont: 'normal 15px Apercu, sans-serif',
  regularBoldFont: 'normal bold 15px Apercu, sans-serif',
  small: '320px',
  phone: '600px',
  tablet: '768px',
  medium: '900px',
  desktop: '1024px',
  breakpoints: [600, 768, 900, 1024],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 15, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    sans: 'Apercu, sans-serif',
    caecilia: 'Caecilia, sans-serif',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  cards: {
    pop: {
      padding: '32px',
      borderRadius: '8px',
      backgroundColor: 'magenta',
      boxShadow: '0 0 48px rgba(0, 0, 0, .25)',
    },
  },
  colors: {
    primary: '#004F71',
    secondary: '#ffffff',
    tertiary: '#DD0031',
    accent: '#004F71',
    background: '#f5f5f5',
    backgroundBlur: '#f5f5f5e8',
    text: '#5B6770',
    outline: '#aaaaaa',
    disabled: '#e5e0e0',
    success: '#249E6B',
    error: '#DD0031',
    warning: '#cca206',
  },
  Button: {
    '&:disabled': {
      color: '#5B6770',
      backgroundColor: '#e5e0e0',
      border: '1px solid #e5e0e0',
      cursor: 'initial',
    },
  },
  buttons: {
    primary: {
      '&:hover:enabled': {
        color: '#004F71',
        backgroundColor: 'white',
        cursor: 'pointer',
      },
    },
    transparent: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '2px',
      '&:hover:enabled': {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    danger: {
      color: 'white',
      backgroundColor: '#DD0031',
      border: '1px solid #DD0031',
      cursor: 'initial',
      '&:hover:enabled': {
        backgroundColor: 'white',
        color: '#DD0031',
        cursor: 'pointer',
      },
    },
    secondary: {
      color: '#004F71',
      backgroundColor: 'white',
      '&:hover:enabled': {
        color: 'white',
        backgroundColor: '#004F71',
        cursor: 'pointer',
      },
    },
  },
};

export default theme;

/*
  primary
    red: #DD0031
    white: #FFFFFF
  secondary:
    red: #AF272F
    navy: #004F71
    aqua: #3EB1C8
    gray: #5B6770
  tertiary
    orange: #FFB549
    pale yellow: #F5E1A4
    forest green: #00635B
    kelly green: #249E6B
    maroon: #5D3754
    plum: #994878
    orange sherbet: #Fa9370
    pink: #F8C1B8
    nude: #F5E3CC
 */
