import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import constants from '../../constants';
import normalizePhone from '../../util/normalizePhone';
import { stripNonPOSSpecialCharacters } from '../../util/utils';
import { validateGuest } from '../../util/validate';
import Disclaimer from '../Disclaimer/Disclaimer';
import ValidatedField from './ValidatedField';

export const GuestForm = (props) => {
  const {
    guestSearch,
    guestSearchEmail,
    guestSearchPhone,
    resultsLength,
    searchIsLoading,
    disableGuestFields,
    disableGuestPhone,
    editMode,
  } = props;
  const renderDisclaimer = () => {
    if (searchIsLoading) {
      return '...Loading search results';
    }
    if (editMode) {
      return 'Guest details cannot be changed while editing an order';
    }
    if (disableGuestFields) {
      return 'Chick-fil-A One customer selected';
    }
    if (resultsLength > 0) {
      return `${resultsLength} Chick-fil-A One customer(s) found`;
    }
    return 'Search for an existing guest or fill out all fields and checkout as guest';
  };

  return (
    <StyledGuestForm autoComplete="nope">
      <Disclaimer>{renderDisclaimer()}</Disclaimer>
      <Flex display="flex" flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Field
            autoComplete="nope"
            component={ValidatedField}
            disabled={disableGuestFields}
            name="email"
            onBlur={guestSearchEmail}
            placeholder="Email (search on full email)"
            type="email"
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestPhone}
            name="phone"
            normalize={normalizePhone}
            onBlur={guestSearchPhone}
            placeholder="Phone number"
            type="text"
            validate={(value) =>
              value && value.length < 12 ? 'Invalid Phone Number' : undefined
            }
          />
        </Box>
      </Flex>
      <Flex display="flex" flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestFields}
            name="first"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={guestSearch}
            placeholder="First Name"
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestFields}
            name="last"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={guestSearch}
            placeholder="Last Name"
            type="text"
          />
        </Box>
      </Flex>
    </StyledGuestForm>
  );
};

const StyledGuestForm = styled(Box)`
  & .disclaimer {
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    height: 15px;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .disclaimer {
      height: 30px;
    }
  }
`;

GuestForm.propTypes = {
  guestSearch: PropTypes.func,
  guestSearchEmail: PropTypes.func,
  guestSearchPhone: PropTypes.func,
  resultsLength: PropTypes.number.isRequired,
  searchIsLoading: PropTypes.bool,
  disableGuestFields: PropTypes.bool,
  disableGuestPhone: PropTypes.bool,
  editMode: PropTypes.bool,
};

GuestForm.defaultProps = {
  guestSearch: () => {},
  guestSearchEmail: () => {},
  guestSearchPhone: () => {},
  searchIsLoading: false,
  disableGuestFields: false,
  disableGuestPhone: false,
  editMode: false,
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.GUEST,
  destroyOnUnmount: false,
  validate: validateGuest,
})(GuestForm);
