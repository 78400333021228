import { Box, Card } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatReadableDate } from '../../util/format';
import DashboardActionButton from '../DashboardActions/DashboardActionButton';
import Icon from '../Icon';
import OrderSummary from '../OrderSummary/OrderSummary';

const PastOrdersCard = ({
  order,
  showReorderButton,
  handleReorderClick,
  openFavoriteModal,
}) => {
  const {
    deliveryAddress,
    lineItems,
    destination,
    promiseDateTime,
    id,
    companyName,
    favoriteOrderId,
    name,
  } = order;
  const { addressLine1, addressLine2, city, state } = deliveryAddress || {};
  return (
    <StyledPastOrdersCard>
      <div className="card-header">
        <Icon
          className={favoriteOrderId ? 'favorite selected' : 'favorite'}
          onClick={() => openFavoriteModal(id, favoriteOrderId, name)}
        >
          favorite
        </Icon>
        {favoriteOrderId ? (
          name
        ) : (
          <>
            <div>{formatReadableDate(promiseDateTime)}</div>
            <div>{destination}</div>
          </>
        )}
      </div>
      {deliveryAddress && (
        <div className="card-delivery-address">
          <Box className="title">Delivery Address:</Box>
          <Box data-cy="guestAddressName">{companyName}</Box>
          <Box data-cy="guestAddressStreet">{addressLine1}</Box>
          <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
          <Box data-cy="guestAddressCityState">{`${city}, ${state}`}</Box>
        </div>
      )}
      <OrderSummary
        cartItems={lineItems}
        className="card-order-summary"
        showModifiers={false}
        showPrices={false}
      />
      {showReorderButton() && (
        <div className="card-footer">
          <DashboardActionButton
            buttonText="Re-Order"
            className="reorder-action"
            icon="refresh"
            onClick={handleReorderClick}
          />
          <DashboardActionButton
            buttonText={
              favoriteOrderId ? 'Edit / Remove Favorite' : 'Add to Favorites'
            }
            className="favorite-action"
            icon="favorite-outline"
            onClick={() => openFavoriteModal(id, favoriteOrderId, name)}
          />
        </div>
      )}
    </StyledPastOrdersCard>
  );
};

const StyledPastOrdersCard = styled(Card)`
  flex: 0 0 31.9%;
  @media (max-width: ${(props) => props.theme.medium}) {
    flex: 0 0 47.5%;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    flex: 0 0 97%;
  }
  & .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.disabled};
    padding: 3px 0 10px;
    font: ${(props) => props.theme.smallBoldFont};
  }
  & .card-order-summary,
  .card-delivery-address {
    padding: 10px;
  }
  & .card-delivery-address,
  .card-order-summary div {
    font: ${(props) => props.theme.smallTextFont};
    line-height: 20px;
  }
  & .card-order-summary .title,
  .card-delivery-address .title {
    font: ${(props) => props.theme.smallBoldFont};
  }
  & .card-footer {
    display: flex;
    margin-top: auto;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background};
  }
  & .card-footer button {
    margin: 10px;
  }
  & .favorite {
    width: 25px;
    height: 20px;
    margin: 0;
    fill: ${(props) => props.theme.colors.disabled};
  }
  & .favorite:hover {
    cursor: pointer;
  }
  & .selected {
    fill: ${(props) => props.theme.colors.error};
  }
`;

PastOrdersCard.propTypes = {
  order: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ).isRequired,
  showReorderButton: PropTypes.func.isRequired,
  handleReorderClick: PropTypes.func,
  openFavoriteModal: PropTypes.func,
};

PastOrdersCard.defaultProps = {
  handleReorderClick: () => {},
  openFavoriteModal: () => {},
};

export default PastOrdersCard;
