import { Box, Text, Toggle as CowponentsToggle } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';

const PromoFreeToggle = ({
  id,
  isToggledPromoFree,
  togglePromoFreeHandler,
}) => (
  <Box className="promo-free-box">
    <Text mr="8px">Promo Free</Text>
    <CowponentsToggle
      checked={isToggledPromoFree}
      data-testid="toggle"
      id={id}
      mr="10px"
      onChange={togglePromoFreeHandler}
    />
  </Box>
);

PromoFreeToggle.propTypes = {
  id: PropTypes.string,
  isToggledPromoFree: PropTypes.bool,
  togglePromoFreeHandler: PropTypes.func,
};

PromoFreeToggle.defaultProps = {
  id: '',
  isToggledPromoFree: false,
  togglePromoFreeHandler: () => {},
};

export default PromoFreeToggle;
