import PropTypes from 'prop-types';
import styled from 'styled-components';

import { camelToTitleCase } from '../../util/utils';
import Icon from '../Icon';

/* eslint-disable react/jsx-one-expression-per-line */
const PaperGoodsBadge = ({ name, setPaperGoodsOptions }) => {
  const onClickHandler = () => {
    setPaperGoodsOptions(name, false);
  };
  return (
    <StyledPaperGoodsBadge>
      {camelToTitleCase(name)}
      <Icon className="icon-close" onClick={onClickHandler}>
        close
      </Icon>
    </StyledPaperGoodsBadge>
  );
};

const StyledPaperGoodsBadge = styled('span')`
  color: ${(props) => props.theme.colors.primary};
  border: solid 1px ${(props) => props.theme.colors.primary};
  padding: 5px;
  margin-left: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 73px;

  & .icon-close {
    max-width: 15px;
    max-height: 15px;
    margin: 0 0 0 5px;
    fill: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    cursor: pointer;
  }
`;

PaperGoodsBadge.propTypes = {
  setPaperGoodsOptions: PropTypes.func,
  name: PropTypes.string,
};

PaperGoodsBadge.defaultProps = {
  setPaperGoodsOptions: () => {},
  name: '',
};

export default PaperGoodsBadge;
