/* eslint-disable react/forbid-prop-types */

import { Button } from '@cfa/react-components';
import { Spinner } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import styled from 'styled-components';

import ooeConstants from '../../constants';
import Disclaimer from '../Disclaimer/Disclaimer';
import Icon from '../Icon';

function SubmitOrderButton({
  selectedMethod,
  submitWarnings,
  isDisabled,
  submitOrder,
  submitOrderLoading,
  isEditMode,
}) {
  const buttonText = useMemo(() => {
    if (isEditMode) {
      return 'Save Changes and Update Order';
    }
    if (selectedMethod === ooeConstants.REQUEST_PAYMENT) {
      return 'Send Email';
    }
    return 'Submit Order';
  }, [isEditMode, selectedMethod]);

  return (
    <>
      {submitWarnings &&
        submitWarnings.map((warning) => (
          <Disclaimer data-testid="disclamer" key={warning.message}>
            <DisclaimerIcon>{warning.type}</DisclaimerIcon>
            {warning.message}
          </Disclaimer>
        ))}
      {submitOrderLoading ? (
        <Spinner />
      ) : (
        <Button
          color="primary"
          data-cy="order-submit"
          disabled={isDisabled}
          fullWidth
          onClick={submitOrder}
          style={{ marginBottom: '80px', marginTop: '10px' }}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}

const DisclaimerIcon = styled(Icon)`
  height: 15px;
  width: 15px;
  margin: 2px 7px -2px 0;
`;

SubmitOrderButton.propTypes = {
  selectedMethod: PropTypes.string,
  submitWarnings: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  submitOrder: PropTypes.func,
  submitOrderLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

SubmitOrderButton.defaultProps = {
  selectedMethod: '',
  submitWarnings: [],
  isDisabled: false,
  submitOrder: () => {},
  submitOrderLoading: false,
  isEditMode: false,
};

export default SubmitOrderButton;
