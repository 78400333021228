import PropTypes from 'prop-types';
import styled from 'styled-components';

import PaperGoodsBadge from './PaperGoodsBadge';

const PaperGoodsBadges = ({ setPaperGoodsOptions, paperGoodsOptions }) => {
  const paperGoods = Object.fromEntries(
    Object.entries(paperGoodsOptions).filter(([, option]) => option === true),
  );
  return (
    <StyledPaperGoodsBadges>
      {Object.getOwnPropertyNames(paperGoods).map((option) => (
        <PaperGoodsBadge
          key={option}
          name={option}
          setPaperGoodsOptions={setPaperGoodsOptions}
        />
      ))}
    </StyledPaperGoodsBadges>
  );
};

const StyledPaperGoodsBadges = styled('div')`
  display: flex;
  margin-left: 10px;
  @media (max-width: 600px) {
    margin: 20px 0 0;
  }
`;

PaperGoodsBadges.propTypes = {
  setPaperGoodsOptions: PropTypes.func,
  paperGoodsOptions: PropTypes.objectOf(PropTypes.bool),
};

PaperGoodsBadges.defaultProps = {
  setPaperGoodsOptions: () => {},
  paperGoodsOptions: {},
};

export default PaperGoodsBadges;
