import {
  constructNow,
  differenceInDays,
  differenceInHours,
  parseISO,
} from 'date-fns';

import ooeConstants from '../constants';
import {
  selectDeliveryMinNotMet,
  selectExceedsMaxOrderTotal,
  selectExceedsSameDayMaxOrderTotal,
  selectLeadTimeWarning,
  selectOrderForAPI,
  selectPickupMinNotMet,
  selectPromoFreeItemsValue,
  selectSubtotalSubmitWarning,
} from '../reducers';
import { selectPromoFreeItemCount } from '../reducers/cart';
import {
  selectDateIsToday,
  selectNewPaymentMethod,
  selectPaymentForm,
} from '../reducers/form';
import {
  selectCfaOneGuestId,
  selectFavoriteOrders,
  selectGuest,
  selectIsCfaOneGuestSelected,
  selectPastOrdersLength,
  selectVaultedCards,
} from '../reducers/guest';
import {
  selectDeliveryAddressValid,
  selectDeliveryRangeWarning,
  selectDeliveryTipAmount,
  selectDestination,
  selectDestinationValid,
  selectEditMode,
  selectEditOrderId,
  selectReorder,
  selectSelectedPercentageTipValue,
  selectSubTotalAmount,
  selectTaxAmount,
} from '../reducers/order';
import {
  doesLocationNumberExist,
  isAdminUser,
  isDeveloperAudience,
  isVcaUser,
  selectBypassBusinessRules,
  selectCmtPerm,
  selectCmtPerms,
  selectDeliveryLeadTimeStoreInfo,
  selectDeliveryRange,
  selectInStateDeliveryOnly,
  selectIsUserLoggedIn,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationNumber,
  selectLocationTimezone,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectOrderMaxFutureDayCountDelivery,
  selectOrderMaxFutureDayCountPickup,
  selectPickupLeadTimeStoreInfo,
  selectUser,
  selectUserCfaGuid,
  selectUserEmail,
  selectUserHasAgreedToEula,
  selectUserName,
} from '../reducers/user';
import ReduxStore from '../util/reduxStore';
import { roundNumber } from '../util/utils';
import { logAmplitudeEvent, setAmplitudeUser } from './amplitude';
import { notifyBugsnag } from './bugsnag';

export const createSubmitOrderAnalytics = (state) => {
  try {
    const order = selectOrderForAPI(state);
    const user = selectUser(state);
    const paymentForm = selectPaymentForm(state);
    const guest = selectGuest(state);

    const now = constructNow(new Date());
    const orderDate = parseISO(order.promiseDateTime);

    const analytics = {
      // top level selectors
      deliveryMinNotMet: selectDeliveryMinNotMet(state),
      pickupMinNotMet: selectPickupMinNotMet(state),
      exceedsMaxOrderTotal: selectExceedsMaxOrderTotal(state),
      exceedsSameDayMaxOrderTotal: selectExceedsSameDayMaxOrderTotal(state),
      subtotalSubmitWarning: selectSubtotalSubmitWarning(state),
      leadTimeWarning: selectLeadTimeWarning(state),

      // order
      requestingNewPaymentMethod: selectNewPaymentMethod(state),
      isCfaOneGuestSelected: selectIsCfaOneGuestSelected(state),
      isEditingOrder: selectEditMode(state),
      editOrderId: selectEditOrderId(state),
      orderId: order.id,
      companyName: order.companyName,
      destination: selectDestination(state),
      deliveryAddressValid: selectDeliveryAddressValid(state),
      destinationValid: selectDestinationValid(state),
      taxAmount: selectTaxAmount(state),
      subTotalAmount: selectSubTotalAmount(state),
      totalAmount: roundNumber(
        selectTaxAmount(state) +
          selectSubTotalAmount(state) +
          selectDeliveryTipAmount(state),
      ),
      deliveryRangeWarning: selectDeliveryRangeWarning(state),
      reorder: selectReorder(state),
      promiseDateTime: order.promiseDateTime,
      hoursUntilPromisedDateTime: roundNumber(
        differenceInHours(orderDate, now),
      ),
      daysUntilPromisedDateTime: roundNumber(differenceInDays(orderDate, now)),
      paperGoods: order.paperGoods,
      specialInstructions: order.specialInstructions,
      guestCount: order.guestCount,
      businessDate: order.businessDate,
      orderLocationNumber: order.locationNumber,
      bypassBusinessRulesList: order.bypassBusinessRulesList,
      orderType: order.orderType,
      deliveryTipAmount: selectDeliveryTipAmount(state),
      deliveryTipPercentOfSale: selectSelectedPercentageTipValue(state),

      //cart
      promoFreeItemCount: selectPromoFreeItemCount(state),
      promoFreeItemsValue: selectPromoFreeItemsValue(state),

      // user
      locationNumber: selectLocationNumber(state),
      bypassBusinessRules: selectBypassBusinessRules(state),
      cmtPerm: selectCmtPerm(state),
      perms: selectCmtPerms(state),
      userCfaGuid: selectUserCfaGuid(state),
      audience: user.cfa_aud,
      isAdmin: isAdminUser(state),
      isVca: isVcaUser(state),
      isDeveloper: isDeveloperAudience(user.cfa_aud),
      isUserLoggedIn: selectIsUserLoggedIn(state),
      locationTimezone: selectLocationTimezone(state),
      userHasAgreedToEula: selectUserHasAgreedToEula(state),
      locationNumberExists: doesLocationNumberExist(
        state,
        order.locationNumber,
      ),
      locationAcceptsDelivery: selectLocationAcceptsDelivery(state),
      locationAcceptsPickup: selectLocationAcceptsPickup(state),
      minDeliveryAmount: selectMinDeliveryAmount(state) / 100,
      minPickupAmount: selectMinPickupAmount(state) / 100,
      maxDeliveryAmount: selectMaxDeliveryAmount(state) / 100,
      maxPickupAmount: selectMaxPickupAmount(state) / 100,
      maxSameDayOrderTotalAmountCents:
        selectMaxSameDayOrderTotalAmountCents(state) / 100,
      orderMaxFutureDayCountDelivery:
        selectOrderMaxFutureDayCountDelivery(state),
      orderMaxFutureDayCountPickup: selectOrderMaxFutureDayCountPickup(state),
      deliveryRange: selectDeliveryRange(state),
      deliveryLeadTimeMinutes: selectDeliveryLeadTimeStoreInfo(state),
      pickupLeadTimeMinutes: selectPickupLeadTimeStoreInfo(state),
      inStateDeliveryOnly: selectInStateDeliveryOnly(state),

      //form
      orderedSameDay: selectDateIsToday(state),
      paymentMethod: paymentForm?.values?.selectedMethod,

      //guest
      favoriteOrdersCount: selectFavoriteOrders(state)?.length,
      pastOrdersCount: selectPastOrdersLength(state),
      cfaOneGuestId: selectCfaOneGuestId(state),
      vaultedCardsCount: selectVaultedCards(state)?.length || 0,
      guestSessionActive: !!guest.guestSession?.accessToken,
      masqueradeSessionActive: !!guest.masqueradeSession?.accessToken,
    };

    return analytics;
  } catch (error) {
    notifyBugsnag('Create Submit Order Analytics Failed', {
      context: 'Order Analytics',
      info: error,
    });
    return {
      error: `Unable to create order analytics -  ${error}`,
    };
  }
};

const getUserInfo = () => {
  const { state } = ReduxStore;
  return {
    isVca: isVcaUser(state) ?? false,
    isAdmin: isAdminUser(state) ?? false,
    userCfaGuid: selectUserCfaGuid(state) ?? '',
    cmtPerms: selectCmtPerm(state) ?? '',
    name: selectUserName(state) ?? '',
    email: selectUserEmail(state) ?? '',
    bypassBusinessRules: selectBypassBusinessRules(state) ?? '',
    locationNumber: selectLocationNumber(state) ?? '',
    appVersion: ooeConstants.APP_VERSION,
  };
};

export function logEvent(event, payload = {}) {
  const userInfo = getUserInfo();
  logAmplitudeEvent(event, { ...payload, ...userInfo });
}

export function simplifyPage(page = '') {
  if (page?.startsWith('more/')) {
    return page.substring(4);
  }
  return page;
}
export function logPageView(page) {
  const userInfo = getUserInfo();
  const simplifiedPage = simplifyPage(page);
  logAmplitudeEvent(`[View Page] ${simplifiedPage}`, {
    page: simplifiedPage,
    ...userInfo,
  });
}

export function setUserLocation(locationNumber) {
  const userInfo = getUserInfo();
  logAmplitudeEvent('[Set User Location]', { locationNumber, ...userInfo });
}

export function setUser(cfaGuid) {
  const userInfo = getUserInfo();
  setAmplitudeUser(cfaGuid, userInfo);
}
