import { isEmpty, pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import {
  actions as guestActions,
  selectApiKey,
  selectGuestTokenExpiryMs,
  selectRefreshToken as selectGuestRefreshToken,
  types as guestTypes,
} from '../reducers/guest';
import {
  actions as userActions,
  selectAccessToken as selectCfaAccessToken,
  selectAccessTokenExpirationDate as selectCfaAccessTokenExpirationDate,
  selectRefreshToken as selectCfaRefreshToken,
  types as userTypes,
} from '../reducers/user';

const retryOrFail = ({ requestFactory, failure, success, store }) => {
  const state = store.value;
  const apiKey = selectApiKey(state);
  const accessToken = selectCfaAccessToken(state);
  const tokens = { apiKey, accessToken };
  const shouldStopRetrying =
    !requestFactory.retryLimitReached || requestFactory.retryLimitReached();
  return requestFactory.retry(tokens).pipe(
    map((res) => success(res)),
    catchError((err) => {
      if (shouldStopRetrying) {
        return of(failure(err.toString()));
      }
      return retryOrFail({
        requestFactory,
        failure,
        success,
        store,
      });
    }),
  );
};

const refreshTokensIfNecessary = (store, requestFactory) => {
  const state = store.value;
  const guestRefreshToken = selectGuestRefreshToken(state);
  const guestTokenExpiryMs = selectGuestTokenExpiryMs(state);
  const cfaRefreshToken = selectCfaRefreshToken(state);
  const cfaAccessTokenExpirationDate =
    selectCfaAccessTokenExpirationDate(state);
  const guestTokenExpired =
    guestRefreshToken && guestTokenExpiryMs && guestTokenExpiryMs < Date.now();
  const cfaTokenExpired =
    cfaRefreshToken &&
    cfaAccessTokenExpirationDate &&
    new Date(cfaAccessTokenExpirationDate * 1000).valueOf() < Date.now();

  let awaitRefresh = false;
  if (guestTokenExpired) {
    if (pathOr('', ['auth', 'type'], requestFactory).indexOf('Bearer') === 0) {
      awaitRefresh = guestActions.refreshToken();
    }
  }

  if (cfaTokenExpired) {
    if (
      pathOr('', ['auth', 'type'], requestFactory).indexOf('JWTBearer') === 0
    ) {
      awaitRefresh = userActions.refreshOktaToken(cfaRefreshToken);
    }
  }
  return awaitRefresh;
};

export default function myEpicHelper(
  requestFactory,
  success,
  failure,
  options = {},
) {
  const { store, action$ } = options;

  const requestFactoryToUse = {
    ...requestFactory,
    execute: requestFactory.execute || (() => requestFactory),
    retry: requestFactory.retry || requestFactory.execute,
  };

  const retryAction$ = action$.pipe(
    ofType(guestTypes.REFRESH_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    take(1),
    switchMap(() =>
      retryOrFail({
        requestFactory: requestFactoryToUse,
        failure,
        success,
        store,
      }),
    ),
  );

  const refreshAction = refreshTokensIfNecessary(store, requestFactory);
  if (refreshAction) {
    return concat(of(refreshAction), retryAction$);
  }

  return requestFactoryToUse.execute().pipe(
    map((res) => success(res)),
    catchError((err) => {
      if (
        err.needsRefresh &&
        (!requestFactoryToUse.retryLimitReached ||
          !requestFactoryToUse.retryLimitReached()) &&
        !isEmpty(requestFactoryToUse.auth)
      ) {
        if (refreshTokensIfNecessary(store)) {
          return retryAction$;
        }
      }
      return of(failure(err.toString()));
    }),
  );
}

export function epicOptions(store, action$, dispatch) {
  return {
    store,
    action$,
    dispatch,
  };
}
